.burn-popup {
    border-radius: 12.796px;
    border: 2.559px solid rgba(95, 118, 242, 0.41);
    background: rgba(9, 12, 15, 0.892);
    -webkit-backdrop-filter: blur(63.98176574707031px);
            backdrop-filter: blur(63.98176574707031px);
    max-width: 575px;
    width: 90%;
    position: fixed;
    /* center */
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 20px 40px 20px;
    z-index: 1000;
}
.burn-popup-bg {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.804);
    z-index: 999;
}
.burn-popup-detail {
    color: #fff;
    text-align: center;
    font-family: Prompt;
    font-size: 23.033px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.115px;
    text-transform: lowercase;
}
.burn-popup > .burn-popup-header {
    text-align: center;
    text-shadow: 0px 0px 42.654510498046875px rgba(255, 216, 79, 0.28);
    font-family: Prompt;
    font-size: 42.655px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.213px;
    text-transform: uppercase;

    background: linear-gradient(90deg, #f0aa22 5.03%, #f5ce3d 28.13%, #fbe350 53.68%, #f3c636 77.27%, #f0aa22 99.39%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.burn-popup-btn.disabled {
    opacity: 0.5;
    cursor: not-allowed;
}
.burn-popup-btn {
    display: flex;
    width: 307.506px;
    height: 84.456px;
    padding: 22.738px 50.888px 22.738px 50.892px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 77.959px;
    border: 1.083px solid #fffa78;
    background: linear-gradient(90deg, #f0aa22 5.03%, #f5ce3d 28.13%, #fbe350 53.68%, #f3c636 77.27%, #f0aa22 99.39%);
    box-shadow: 0px 0px 20.5726px 5.41384px rgba(251, 255, 33, 0.28);
}
.burn-popup-btn:hover {
    scale: 1.02;
}
.burn-popup-btn > div {
    color: #734700;
    text-align: center;
    font-family: Prompt;
    font-size: 25.986px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
