.home-desk-footer-col-2 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 26px;
    height: 100.52px;
    position: relative;
    width: 144px;
}

.home-desk-footer-col-3 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 26px;
    height: 101px;
    position: relative;
    width: 213.75px;
}
.home-desk-footer-col {
    align-items: flex-start;
    display: block;
    flex-direction: column;
    gap: 26px;
    height: 60.11px;
    position: relative;
    width: 232.3px;
}

.home-desk-footer-section {
    width: 100vw;
    background-color: #090c0f;
    justify-content: space-between;
    align-items: flex-start;
    padding: 74px 10% 40px;
    display: flex;
    /*position: absolute;*/
    position: static;
    bottom: -298px;
    height: 298px;
    left: 0;
}

.home-desk-facebook-text-wrapper-4 {
    color: #808080;
    font-family: "Prompt", Helvetica;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0.08px;
    line-height: 14px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    transition: color 0.2s ease, font-size 0.2s ease;
}

.home-desk-facebook-menu:hover .home-desk-facebook-text-wrapper-4 {
    font-size: 20px;
    color: #febd16;
}

.home-desk-facebook-menu:hover .home-desk-icon-facebook {
    content: url("https://iconsplace.com/wp-content/uploads/_icons/febd16/256/png/facebook-icon-19-256.png");
}

@media screen and (min-width: 2559px) {
    /* .home-desk-footer-section {
    padding: 74px 40% 40px;
  } */
    .home-desk-footer-col-2 {
        margin-right: 100px;
    }
}

@media (max-width: 425px) {
    .home-desk-footer-section {
        padding: 180px 5% 15px 50px;
    }
}

@media (min-width: 426px) and (max-width: 768px) {
    .home-desk-footer-section {
        padding: 550px 3% 182px 90px;
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
    .home-desk-footer-section {
        position: relative;
        margin-top: 25%;
    }
}
@media screen and (max-width: 767px) {
    .home-desk-footer-col {
        margin-bottom: 40px;
    }
    .home-desk-footer-col-2 {
        margin-bottom: 40px;
    }
    .home-desk-footer-col-3 {
        margin-bottom: 40px;
    }
}

@media screen and (max-width: 425px) {
    .home-desk-footer-col-3 {
        display: none;
    }
}
