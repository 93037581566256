.forbidden-screen{
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    flex-direction: column;
    background-color: #000000;
    color: #a7a7a7;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 2rem;
}