.itembox-desktop-product-name-round-number{
    background-color: #ff9603;
    border-radius: 30px;
    position: absolute;
    width: 100px;
    padding-top: 4px;
    padding-bottom: 4px;
    bottom: -5px;
    left: 50%;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
}
.inventory-desktop-inventory-desktop {
    align-items: center;
    background-color: #131313;
    border: 1px none;
    display: flex;
    flex-direction: column;
    height: 100%;
    min-width: 100vw;
    position: relative;
}

.inventory-desktop-BG {
    height: 1093px;
    left: 1px;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: calc(100% - 1px);
}

.inventory-desktop-content-section {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 39px;
    padding-top: 124px;
    padding-bottom: 150px;
    position: relative;
    /*width: 100vw;*/
    margin-bottom: 96px;
}

.bg-bulr {
    -webkit-backdrop-filter: blur(0) brightness(100%);
    backdrop-filter: blur(0px) brightness(100%);
    background: linear-gradient(
        90deg,
        rgba(223, 162, 47, 0.1) 0%,
        rgba(223, 162, 47, 0.05) 50%,
        rgba(223, 162, 47, 0.1) 100%
    );
}

.inventory-desktop-your-inventory-title {
    align-items: center;
    display: flex;
    gap: 28.31px;
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding-bottom: 30px;
}

.inventory-desktop-left-line {
    height: 6.66px;
    min-width: 365.51px;
    position: relative;
}

.inventory-desktop-div {
    height: 7px;
    position: relative;
    width: 366px;
}

.inventory-desktop-line {
    height: 2px;
    left: 0;
    position: absolute;
    top: 2px;
    width: 360px;
}

.inventory-desktop-ellipse {
    background-color: #febd16;
    border-radius: 3.33px;
    height: 7px;
    left: 359px;
    position: absolute;
    top: 0;
    width: 7px;
}

.inventory-desktop-title {
    height: 28px;
    min-width: 243px;
    position: relative;
}

.inventory-desktop-label {
    background: linear-gradient(90deg, rgba(240, 170, 34, 1) 0%, rgba(249, 219, 72, 1) 49%, rgba(240, 170, 34, 1) 100%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "Prompt", Helvetica;
    font-size: 40px;
    font-weight: 700;
    letter-spacing: 0.12px;
    line-height: 45px;
    margin-top: -1px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.inventory-desktop-right-line {
    height: 6.66px;
    min-width: 365.51px;
    position: relative;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

.inventory-desktop-img {
    height: 2px;
    left: 0;
    position: absolute;
    top: 3px;
    -webkit-transform: rotate(-180deg);
            transform: rotate(-180deg);
    width: 360px;
}

.inventory-desktop-description-label {
    color: #ffffff;
    font-family: "Prompt", Helvetica;
    font-size: 28px;
    font-weight: 400;
    letter-spacing: 0.14px;
    line-height: normal;
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.inventory-desktop-select-product {
    -webkit-backdrop-filter: blur(100px) brightness(100%);
    align-items: flex-start;
    backdrop-filter: blur(100px) brightness(100%);
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.01) 0%, rgba(0, 0, 0, 0.01) 100%);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 16px 34px;
    position: relative;
    width: 1140px;
}

.inventory-desktop-select-product-2 {
    align-items: center;
    display: flex;
    gap: 69px;
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.inventory-desktop-select-product-list {
    align-items: flex-start;
    display: flex;
    gap: 35px;
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.inventory-desktop-select-product-label {
    color: #ffffff;
    font-family: "Prompt", Helvetica;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0.12px;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.inventory-desktop-element {
    color: transparent;
    font-family: "Prompt", Helvetica;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0.12px;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.inventory-desktop-text-wrapper {
    color: #ffffff;
}

.inventory-desktop-span {
    color: #ecc834;
}

.inventory-desktop-div-2 {
    align-items: center;
    display: flex;
    gap: 22px;
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.inventory-desktop-total-price-label {
    color: #ffffff;
    font-family: "Prompt", Helvetica;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0.12px;
    line-height: normal;
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.inventory-desktop-total-price {
    align-items: flex-start;
    background-color: #01000180;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 10px 12px;
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.inventory-desktop-total-price-label-2 {
    align-items: flex-start;
    display: flex;
    gap: 10px;
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.inventory-desktop-text-wrapper-2 {
    color: #febd16;
    font-family: "Prompt", Helvetica;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.12px;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.inventory-desktop-button {
    align-items: flex-start;
    display: flex;
    gap: 36px;
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.inventory-desktop-sell-product-button {
    align-items: flex-start;
    border-color: transparent;
    border-radius: 28.73px;
    box-shadow: 0px 0px 23.65px 6.22px #faff2047;
    display: flex;
    gap: 8.85px;
    padding: 15.04px 23.89px;
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    cursor: pointer;
}
.inventory-desktop-sell-product-button:hover {
    align-items: flex-start;
    border-color: transparent;
    border-radius: 28.73px;
    box-shadow: 0px 0px 23.65px 6.22px #d6da05e5;
    display: flex;
    gap: 8.85px;
    padding: 15.04px 23.89px;
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    cursor: pointer;
}

.inventory-desktop-sell-product-label {
    color: #ffeb3c;
    font-family: "Prompt", Helvetica;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0.1px;
    line-height: 17.5px;
    margin-top: -1.24px;
    position: relative;
    text-align: center;
    text-shadow: 0px 0px 23.65px 6.22px #faff2047;
    white-space: nowrap;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.inventory-desktop-delivery-product-button {
    align-items: flex-start;
    background: linear-gradient(180deg, rgb(40, 219, 194) 0%, rgb(67, 131, 222) 52.6%, rgb(136, 80, 243) 96.88%);
    border-radius: 29.87px;
    display: flex;
    gap: 8.85px;
    padding: 15.04px 23.89px;
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    cursor: pointer;
}
.inventory-desktop-delivery-product-button:hover {
    align-items: flex-start;
    box-shadow: 0px 0px 10.65px 2.22px rgb(0, 216, 245);
    background: linear-gradient(180deg, rgb(40, 219, 194) 0%, rgb(67, 131, 222) 52.6%, rgb(136, 80, 243) 96.88%);
    border-radius: 29.87px;
    display: flex;
    gap: 8.85px;
    padding: 15.04px 23.89px;
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    cursor: pointer;
}

.inventory-desktop-delivery-product-label {
    color: #ffffff;
    font-family: "Prompt", Helvetica;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0.1px;
    line-height: 17.5px;
    margin-top: -0.88px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.inventory-desktop-inventory {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 39px;
    margin-bottom: -50px;
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-top: 20px;
}

.inventory-desktop-BG-blur {
    background: rgba(26, 19, 9, 0.719);
    border: 1px solid #48476e;
    border-radius: 20px;
    height: 623px;
    left: calc(50% - 570px);
    position: absolute;
    top: -26px;
    width: 1141px;
}

.inventory-desktop-search-my-product {
    align-items: center;
    display: flex;
    gap: 38px;
    justify-content: center;
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.inventory-desktop-search-product {
    background-color: #01000180;
    border-radius: 10px;
    height: 60px;
    min-width: 715px;
    position: relative;
}
.inventory-desktop-no-product {
    color: #fcfbfb;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    font-weight: 600;
    min-height: 150px;
    font-size: large;
}
.inventory-desktop-search-input {
    align-items: center;
    display: flex;
    gap: 29px;
    left: 28px;
    position: relative;
    top: 10px;
    width: 666px;
}

.inventory-desktop-img-2 {
    height: 24px;
    min-width: 24px;
    position: relative;
}

.inventory-desktop-text-wrapper-3 {
    color: #ffffff;
    font-family: "Prompt", Helvetica;
    font-size: 18px;
    font-weight: 500;
    background: none;
    letter-spacing: 0.09px;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: 100%;
    border: none;
    outline: none;
}
.pointer {
    cursor: pointer;
}
.inventory-desktop-text-wrapper-3:focus {
    outline: none;
    border: none;
    box-shadow: none;
}

.inventory-desktop-select-all {
    align-items: center;
    display: flex;
    gap: 16px;
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    color: #ffff;
    cursor: pointer;
}

.inventory-desktop-row {
    align-items: flex-start;
    display: flex;

    flex-wrap: wrap;
    gap: 12.02px;
    padding: 2px 0px;
    position: relative;
    width: 100vw;
    overflow-y: scroll;
    overflow-x: hidden;
    margin: 50px 50px 50px 50px;
}

.inventory-box-flex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    /*justify-content: center;*/
    align-items: center;
    max-width: 90vw;
    /* min-height: 100%; */
    /* padding: 10px 10px 10px 10px; */
    border: 3px solid #716544;
    border-radius: 29px;
    background: rgb(77, 69, 50);
    background: linear-gradient(167deg, rgba(77, 69, 50, 1) 0%, rgba(36, 31, 22, 1) 35%, rgba(23, 20, 16, 1) 100%);
}

@media (max-width: 768px) {
    .inventory-box-flex {
        max-width: 70vw;
    }
}

@media (max-width: 425px) {
    .inventory-box-flex {
        max-width: 70vw;
    }
}

.inventory-desktop-row::-webkit-scrollbar {
    width: 8px;
}

.inventory-desktop-row::-webkit-scrollbar-track {
    background-color: #080101;
}

.inventory-desktop-row::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
}

.inventory-desktop-row::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}

input[type="checkbox"] {
    -webkit-appearance: none;
    appearance: none;
    width: 18px;
    height: 18px;
    border: 1px solid #bbb;
    border-radius: 3px;
    outline: none;
    transition: all 0.3s;
    cursor: pointer;
    position: relative;
}

input[type="checkbox"]:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 10px;
    height: 10px;
    border-radius: 2px;
    background-color: transparent;
    transition: all 0.3s;
}

input[type="checkbox"]:checked:before {
    background-color: #2f80ed;
}

input[type="checkbox"]:hover {
    border-color: #2f80ed;
}

input[type="checkbox"]:focus {
    box-shadow: 0 0 0 3px rgb(47, 129, 237);
}

@media screen and (max-width: 768px) {
    /* This CSS code is hiding the left and right lines in the inventory desktop section when the screen
 size is smaller than 768px or 1140px respectively. This is likely done to improve the
 responsiveness and layout of the inventory desktop section on smaller screens. */
    .inventory-desktop-left-line {
        display: none;
    }
    .inventory-desktop-right-line {
        display: none;
    }
}

@media screen and (max-width: 1140px) {
    /* This CSS code is hiding the left and right lines in the inventory desktop section when the screen
  size is smaller than 768px or 1140px respectively. This is likely done to improve the
  responsiveness and layout of the inventory desktop section on smaller screens. */
    .inventory-desktop-select-product-label {
        display: none;
    }
    .inventory-desktop-left-line {
        display: none;
    }
    .inventory-desktop-right-line {
        display: none;
    }
    .inventory-desktop-select-product {
        width: 100vw;
        align-items: center;
    }
    .inventory-desktop-select-product-2 {
        scale: 0.8;
        justify-content: center;
        width: 80w;
        gap: 20px;
    }
    .inventory-desktop-inventory,
    .inventory-desktop-BG-blur {
        width: 100vw;
    }
    .inventory-desktop-row {
        flex-wrap: wrap;
    }
    .inventory-desktop-BG-blur {
        left: 0px;
    }
    .inventory-desktop-row,
    .inventory-desktop-search-my-product {
        width: 100vw;
    }
    .inventory-desktop-search-product {
        width: 50vw;
        min-width: 0px;
    }
    .inventory-desktop-search-input {
        width: 40vw;
    }
}
@media screen and (max-width: 765px) {
    .inventory-desktop-select-product-2 {
        scale: 0.6;
        justify-content: center;
    }
    .inventory-desktop-text-wrapper,
    .inventory-desktop-select-product-label,
    .inventory-desktop-span,
    .inventory-desktop-total-price-label {
        font-size: 20px;
    }
    .inventory-desktop-button {
        scale: 0.9;
        flex-direction: column;
        align-items: center;
    }
    .inventory-desktop-select-product {
        padding: 16px 16px;
        height: 80px;
        justify-content: center;
    }
    .inventory-desktop-description-label {
        font-size: 20px;
        padding: 20px;
    }
}
