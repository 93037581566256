.topup-desktop {
    align-items: center;
    background-color: #131313;
    border: 1px none;
    display: flex;
    flex-direction: column;
    min-width: 100vw;
    position: relative;
}

.wing {
    display: inline;
    width: 100%;
}

.wingbox {
    width: 100vw;
}

.handle-error-text {
    left: 222px;
    bottom: 20px;
}

@media (min-width: 375px) and (max-width: 424px) {
  .handle-error-text {
      left: 30px;
      bottom: 100px
  }
}

@media (min-width: 425px) and (max-width: 767px) {
    .handle-error-text {
        left: 40px;
        bottom: 100px
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .handle-error-text {
        left: 175px;
    }
}

@media screen and (max-width: 1024px) {
    .wing {
        display: none;
    }

    .boxOutlay {
        width: 80%;
    }
}

@media screen and (max-width: 500px) {
    .col1,
    .bankNum,
    .userName {
        font-size: 15px;
    }

    .bankName,
    .label {
        font-size: 13px;
    }
}

.modal {
    display: block;
    position: absolute;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.modal-head {
    border-bottom: 1px solid gray;
}

.modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: auto;
    background-color: white;
    border-radius: 5px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}