.itemresult-desktop-itemresult-desktop {
    align-items: center;
    background-color: #131313;
    border: 1px none;
    display: flex;
    flex-direction: column;
    height: 2312px;
    min-width: 100vw;
    position: relative;
}

.itemresult-desktop-BG {
    height: 1448px;
    left: 0;
    position: absolute;
    top: 588px;
    width: 100%;
}

.itemresult-desktop-header-section {
    align-items: flex-start;
    display: flex;
    gap: 10px;
    height: 734px;
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.itemresult-desktop-BG-header {
    height: 600px;
    left: -6px;
    object-fit: cover;
    position: absolute;
    top: -6px;
    width: calc(100% + 12px);
}

.itemresult-desktop-hero-section {
    height: 734px;
    min-width: 100vw;
    position: relative;
}

.itemresult-desktop-product-result {
    align-items: center;
    display: flex;
    gap: 10px;
    justify-content: center;
    border-radius: 8px;
    position: relative;
    opacity: 0;
    -webkit-animation: fadeIn 0.5s ease-in-out forwards;
            animation: fadeIn 0.5s ease-in-out forwards;
}

@-webkit-keyframes fadeIn {
    from {
        opacity: 0;
        scale: 0.5;
    }
    to {
        opacity: 1;
        scale: 1;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
        scale: 0.5;
    }
    to {
        opacity: 1;
        scale: 1;
    }
}

.itemresult-desktop-img {
    height: 543px;
    left: 0;
    position: absolute;
    top: 0;
    width: 1130px;
    opacity: 80%;
}

.itemresult-desktop-product-get {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 1 1;
    flex-direction: column;
    gap: 28px;
    padding: 18px 0px;
    position: relative;
}

.itemresult-desktop-box-title {
    display: flex;
    gap: 21px;
    position: relative;
}

.itemresult-desktop-box-img {
    height: 85.7px;
    min-width: 78.18px;
    object-fit: cover;
    position: relative;
}

.itemresult-desktop-name-box-detail {
    color: #888888;
    font-family: "Prompt", Helvetica;
    font-size: 15.9px;
    font-weight: 700;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    text-overflow: ellipsis;
}
.itemresult-desktop-name-box {
    color: #ffffff;
    font-family: "Prompt", Helvetica;
    font-size: 45.9px;
    font-weight: 700;
    top: 10px;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    text-overflow: ellipsis;
}

.itemresult-desktop-description-box {
    color: #ffffff;
    font-family: "Prompt", Helvetica;
    font-size: 14.2px;
    font-weight: 400;
    letter-spacing: 0.07px;
    line-height: normal;
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.itemresult-desktop-product {
    height: 293.23px;
    min-width: 240.87px;
    position: relative;
}

.itemresult-desktop-overlap {
    border-radius: 7.48px;
    height: 293px;
    position: relative;
    width: 241px;
}

.itemresult-desktop-BG-group-grade-x {
    -webkit-backdrop-filter: blur(19.59px) brightness(100%);
    backdrop-filter: blur(19.59px) brightness(100%);
    background: linear-gradient(137deg, rgba(227, 197, 138, 0.17) 1.29%, rgba(0, 0, 0, 0.01) 98.15%);
    border-radius: 7.48px;
    box-shadow: 0px 0px 28.8px #000000;
    height: 293px;
    left: 0;
    position: absolute;
    top: 0;
    width: 241px;
}

.itemresult-desktop-BG-group-grade-0 {
    -webkit-backdrop-filter: blur(19.59px) brightness(100%);
    backdrop-filter: blur(19.59px) brightness(100%);
    background: linear-gradient(137deg, rgba(227, 197, 138, 0.17) 1.29%, rgba(247, 42, 42, 0.01) 98.15%);
    border-radius: 7.48px;
    box-shadow: 0px 0px 28.8px #ffc444;
    height: 293px;
    left: 0;
    position: absolute;
    top: 0;
    width: 241px;
    -webkit-animation: boxShadowPulse 1.5s infinite;
            animation: boxShadowPulse 1.5s infinite;

}
@-webkit-keyframes boxShadowPulse {
    0% {
        box-shadow: 0px 0px 70.8px #ffc444;
    }
    50% {
        box-shadow: 0px 0px 100px #e4a804;
    }
    100% {
        box-shadow: 0px 0px 70.8px #ffc444;
    }
}
@keyframes boxShadowPulse {
    0% {
        box-shadow: 0px 0px 70.8px #ffc444;
    }
    50% {
        box-shadow: 0px 0px 100px #e4a804;
    }
    100% {
        box-shadow: 0px 0px 70.8px #ffc444;
    }
}

.itemresult-desktop-BG-group-grade-1 {
    -webkit-backdrop-filter: blur(19.59px) brightness(100%);
    backdrop-filter: blur(19.59px) brightness(100%);
    background: linear-gradient(137deg, rgba(227, 197, 138, 0.17) 1.29%, rgba(0, 0, 0, 0.01) 98.15%);
    border-radius: 7.48px;
    box-shadow: 0px 0px 28.8px rgba(239, 215, 130, 0.68);
    height: 293px;
    left: 0;
    position: absolute;
    top: 0;
    width: 241px;
}

.itemresult-desktop-BG-group-grade-2 {
    -webkit-backdrop-filter: blur(19.59px) brightness(100%);
    backdrop-filter: blur(19.59px) brightness(100%);
    background: linear-gradient(137deg, rgba(227, 197, 138, 0.17) 1.29%, rgba(0, 0, 0, 0.01) 98.15%);
    border-radius: 7.48px;
    box-shadow: 0px 0px 28.8px rgba(239, 215, 130, 0.68);
    height: 293px;
    left: 0;
    position: absolute;
    top: 0;
    width: 241px;
}

.itemresult-desktop-BG-group-grade-3 {
    -webkit-backdrop-filter: blur(19.59px) brightness(100%);
    backdrop-filter: blur(19.59px) brightness(100%);
    background: linear-gradient(137deg, rgba(227, 197, 138, 0.17) 1.29%, rgba(0, 0, 0, 0.01) 98.15%);
    border-radius: 7.48px;
    box-shadow: 0px 0px 28.8px rgba(239, 215, 130, 0.68);
    height: 293px;
    left: 0;
    position: absolute;
    top: 0;
    width: 241px;
}

.itemresult-desktop-BG-group-grade-4 {
    -webkit-backdrop-filter: blur(19.59px) brightness(100%);
    backdrop-filter: blur(19.59px) brightness(100%);
    background: linear-gradient(137deg, rgba(227, 197, 138, 0.17) 1.29%, rgba(0, 0, 0, 0.01) 98.15%);
    border-radius: 7.48px;
    box-shadow: 0px 0px 28.8px #d95752;
    height: 293px;
    left: 0;
    position: absolute;
    top: 0;
    width: 241px;
}

.itemresult-desktop-BG-group-grade-5 {
    -webkit-backdrop-filter: blur(19.59px) brightness(100%);
    backdrop-filter: blur(19.59px) brightness(100%);
    background: linear-gradient(137deg, rgba(227, 197, 138, 0.17) 1.29%, rgba(0, 0, 0, 0.01) 98.15%);
    border-radius: 7.48px;
    box-shadow: 0px 0px 28.8px #ecc834;
    height: 293px;
    left: 0;
    position: absolute;
    top: 0;
    width: 241px;
}

.itemresult-desktop-overlap-group {
    background: linear-gradient(180deg, rgba(52, 170.4, 237, 0) 0%, #b08f09 100%);
    border-radius: 7.48px;
    height: 126px;
    position: relative;
    top: 168px;
}

.itemresult-desktop-line {
    border-radius: 7.48px;
    height: 6px;
    position: relative;
    top: 120px;
    width: 241px;
}

.itemresult-desktop-product-detail {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 43px;
    height: 259px;
    position: absolute;
    top: 17px;
    width: 100%;
}

.itemresult-desktop-frame {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 26px;
    position: relative;
}

.itemresult-desktop-price-label {
    height: 31px;
    min-width: 156.5px;
    position: relative;
}

.itemresult-desktop-div {
    height: 31px;
    position: relative;
    width: 155px;
}

.itemresult-desktop-price-BG {
    -webkit-backdrop-filter: blur(4px) brightness(100%);
    backdrop-filter: blur(4px) brightness(100%);
    border-radius: 60.923px;
    border: 0.846px solid #fffa78;
    box-shadow: 0px 0px 16.07692px 4.23077px rgba(251, 255, 33, 0.28);
    height: 31px;
    left: 0;
    position: absolute;
    top: 0;
    width: 148px;
}

.itemresult-desktop-text-wrapper {
    color: #ffffff;
    font-family: "Prompt", Helvetica;
    font-size: 18px;
    font-weight: 500;
    left: 0;
    letter-spacing: 0.09px;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 1px;
    width: 155px;
}

.itemresult-desktop-product-image {
    height: 101.12px;
    min-width: 166.73px;
    object-fit: contain;
    position: relative;
    -webkit-animation: scaleUpDown 0.6s forwards;
            animation: scaleUpDown 0.6s forwards;
    z-index: 100;
}
@-webkit-keyframes scaleUpDown {
    0% {
        -webkit-transform: scale(100);
                transform: scale(100); /* Start at the original size */
    }
    100% {
        -webkit-transform: scale(1);
                transform: scale(1); /* Return to the original size */
    }
}
@keyframes scaleUpDown {
    0% {
        -webkit-transform: scale(100);
                transform: scale(100); /* Start at the original size */
    }
    100% {
        -webkit-transform: scale(1);
                transform: scale(1); /* Return to the original size */
    }
}

.itemresult-desktop-product-title {
    color: #ffffff;
    font-family: "Prompt", Helvetica;
    font-size: 18.9px;
    font-weight: 500;
    height: 20.5px;
    top: -10px;
    letter-spacing: 0.09px;
    line-height: normal;
    position: relative;
    text-align: center;
}

.itemresult-desktop-product-info {
    font-family: "Prompt", Helvetica;
    font-size: 18.9px;
    font-weight: 500;
    width: 100%;
    bottom: -5px;
    text-overflow: ellipsis;
    letter-spacing: 0.09px;
    line-height: normal;
    position: relative;
    text-align: center;
}

.itemresult-desktop-button-group {
    gap: 18px;
    position: relative;
}

.itemresult-desktop-sell-button {
    height: 44px;
    min-width: 292px;
    position: relative;
}

.itemresult-desktop-sell-button-label-wrapper {
    background-color: #181c1d;
    border: 0.5px solid;
    border-color: #ffffff;
    border-radius: 22px;
    height: 44px;
    position: relative;
    width: 290px;
    cursor: pointer;
}

.itemresult-desktop-sell-button-label {
    color: #949494;
    font-family: "Prompt", Helvetica;
    font-size: 20px;
    font-weight: 400;
    left: 8px;
    letter-spacing: 0.1px;
    line-height: 17.5px;
    position: absolute;
    text-align: center;
    top: 12px;
    white-space: nowrap;
    width: 274px;
    cursor: pointer;
}

.itemresult-desktop-open-again-button {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 11px;
    position: relative;
    width: 100%;
    cursor: pointer;
}

.itemresult-desktop-overlap-group-wrapper {
    height: 45px;
    margin-right: -2px;
    width: 100%;
    position: relative;
}

.itemresult-desktop-overlap-group-2 {
    height: 45px;
    position: relative;
    width: 100%;
}

.itemresult-desktop-open-again-button-label {
    color: #734700;
    font-family: "Prompt", Helvetica;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    left: 24px;
    letter-spacing: 0.1px;
    line-height: 17.5px;
    text-align: center;
    top: 12px;
    background: linear-gradient(90deg, #f0aa22 5.03%, #f5ce3d 28.13%, #fbe350 53.68%, #f3c636 77.27%, #f0aa22 99.39%);
    border-radius: 22px;
    height: 44px;
}

.itemresult-desktop-open-again-description {
    color: #f5c935;
    font-family: "Prompt", Helvetica;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.07px;
    line-height: 12.3px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}
