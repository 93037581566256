.shipping-success-desktop-shipping-list-item {
  /* background-image: url(../../../../public/img/shipping/bg-1.png); */
  background-repeat: no-repeat;
  position: relative;
  -webkit-backdrop-filter: blur(100px) brightness(100%);
  backdrop-filter: blur(20px) brightness(100%); /* 
  background: linear-gradient(180deg, rgba(78, 67, 49, 0.616) 0%, rgba(41, 31, 14, 0.575) 100%); */
  border: 0.5px solid;
  border-color: #3c4069;
  border-radius: 7px; 
  background: rgba(20, 15, 7, 0.719);
  padding: 10px;
/*    filter: blur(8px);
  -webkit-filter: blur(8px); */

}

.shipping-success-desktop-shipping-detail {
  align-items: flex-end;
  display: flex;
  gap: 37px;
  left: 43px;
  position: relative;
  top: 27px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  /* overflow: scroll; Problem*/ 
  
}

.shipping-success-desktop-shipping-detail-2 {
  align-items: center;
  display: flex;
  gap: 76px;
  position: relative;
  width: 847px;
  margin-bottom: 60px;
}

.shipping-success-desktop-product-image {
  height: 166px;
  width: 178px;
  position: relative;
}

.shipping-success-desktop-shipping-detail-3 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 12px;
  position: relative;
  width: 574px;
}

.shipping-success-desktop-date-get-product {
  align-items: flex-start;
  display: flex;
  gap: 32px;
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.shipping-success-desktop-text-wrapper-2 {
  color: #ffffff;
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: start;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.shipping-success-desktop-text-wrapper-3 {
  color: #ffb500;
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.shipping-success-desktop-parcel-number {
  align-items: center;
  display: flex;
  gap: 37px;
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.shipping-success-desktop-text-wrapper-4 {
  color: #ffffff;
  font-family: "Prompt", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: left;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.shipping-success-desktop-air-jordan-retro-SUPREME-LUCKY-air-jordan-retro {
  color: #ffb500;
  font-family: "Prompt", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: left;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.shipping-success-desktop-text-wrapper-5 {
  color: #ffffff;
  font-family: "Prompt", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
  text-decoration: underline;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  cursor: pointer;
}

.shipping-success-desktop-product-detail {
  align-items: flex-start;
  display: flex;
  gap: 10px;
  margin-right: -37px;
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.shipping-success-desktop-span {
  color: #ffb500;
  font-family: "Prompt", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
}

.shipping-success-desktop-overlap-group-wrapper {
  height: 100px;
  margin-right: -2px;
  width: 576px;
  position: relative;
}

.shipping-success-desktop-overlap-group {
  border-radius: 7px;
  height: 100px;
  position: relative;
  width: 574px;
}

.shipping-success-desktop-address-detail {
  color: #ffffff;
  font-family: "Prompt", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 25px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 14px;
  width: 501px;

  /*Overflow*/
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.shipping-success-desktop-BG-2 {
  border: 0.5px solid;
  border-color: #ffffff;
  border-radius: 7px;
  height: 100px;
  left: 0;
  position: absolute;
  top: 0;
  width: 574px;
}

.shipping-success-desktop-product-detail-2 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 137px;
  justify-content: center;
  position: relative;
  top: -60px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.shipping-success-desktop-text-wrapper-6 {
  color: #7ed46c;
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.shipping-success-desktop-address-detail-button {
  align-items: center;
  background: linear-gradient(90deg, rgba(240,170,34,1) 0%, rgba(251,227,80,1) 49%, rgba(240,170,34,1) 100%);
  border-radius: 29.87px;
  display: flex;
  flex-direction: column;
  gap: 8.85px;
  justify-content: center;
  padding: 13px 25px;
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.shipping-success-desktop-address-detail-label-wrapper {
  align-items: center;
  display: flex;
  gap: 9.5px;
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.shipping-success-desktop-address-detail-label {
  color: #000;
  font-family: "Prompt", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.08px;
  line-height: 14px;
  margin-top: -0.59px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  cursor: pointer;
}

@media screen and (max-width :700px) {
  .shipping-success-desktop-overlap-group-wrapper{
    width : 100% ;
  }
  .shipping-success-desktop-overlap-group{
    width : 100%
  }
  .shipping-success-desktop-address-detail{
    width: 100%;

  }
  .shipping-success-desktop-BG-2{
    width: 95%
  }
  .shipping-success-desktop-product-image{
    
  }
  .shipping-success-desktop-shipping-detail-3{
    scale: 0.8;
  
  }
  .shipping-success-desktop-text-wrapper-2,.shipping-success-desktop-text-wrapper-4,.shipping-success-desktop-text-wrapper-4,
  .shipping-success-desktop-span,.shipping-success-desktop-air-jordan-retro-SUPREME-LUCKY-air-jordan-retro,.shipping-success-desktop-text-wrapper-3{
    font-size: 14px;
  }
  .shipping-success-desktop-shipping-detail{
    left: 0px;
    top: 0px ;
    
  }
  .shipping-success-desktop-product-detail-2{
    scale: 0.7;
    
  }
}
@media screen and (max-width:1200px) {
  .shipping-success-desktop-address-detail{
    width: 100%;
    scale: 0.97;
    left: 4px;
  }
  .shipping-success-desktop-shipping-list-item{
    width: 100%;
  }
  .shipping-success-desktop-shipping-detail{
    width: 100%
    
  }
  .shipping-success-desktop-shipping-detail-2{
    width: 60%;
    gap: 20px;
  }
  .shipping-success-desktop-product-image{
    scale: 0.6;
  }
  .shipping-success-desktop-shipping-detail-3{
    width: 100%;
  }
  .shipping-success-desktop-overlap-group-wrapper,.shipping-success-desktop-overlap-group,.shipping-success-desktop-BG-2{
    width: 100%;
  }


@media screen and (max-width : 540px) {
  .shipping-success-desktop-product-detail-2{
    scale: 0.7;
    ;
    }

  .shipping-success-desktop-product-image{
    display: none;
  }
  }
}