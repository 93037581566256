.MainBox-MainBox-wrapper {
    background-color: #0000;
    flex-direction: row;
    justify-content: center;
    display: flex;
    height: 100%;
    max-width: 500px;
}

.animated-hide {
    -webkit-animation: fadeOut 0.5s;
            animation: fadeOut 0.5s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}

.congratulations-text {
    position: absolute;
    right: -210px;
    top: -280px;
    font-weight: 900;
    font-size: 5rem;
    text-shadow: rgb(0, 0, 0) 0px 0px 10px;
    color: rgb(255, 251, 0);
    -webkit-animation: scale3 1s ease-in-out infinite alternate;
            animation: scale3 1s ease-in-out infinite alternate;
}

.animated-show {
    -webkit-animation: fadeIn 0.7s;
            animation: fadeIn 0.7s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}
@-webkit-keyframes fadeIn {
    0% {
        opacity: 1;
        scale: 0.5;
    }
    100% {
        scale: 1;
        opacity: 1;
    }
}
@keyframes fadeIn {
    0% {
        opacity: 1;
        scale: 0.5;
    }
    100% {
        scale: 1;
        opacity: 1;
    }
}
@-webkit-keyframes fadeOut {
    0% {
        opacity: 1;
        scale: 1;
    }
    100% {
        scale: 1;
        opacity: 0;
    }
}
@keyframes fadeOut {
    0% {
        opacity: 1;
        scale: 1;
    }
    100% {
        scale: 1;
        opacity: 0;
    }
}
.MainBox-box-content {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 36px;
    height: 100%;
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.MainBox-header-name-box {
    margin: 0 auto;
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 13px;
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.MainBox-box-name-group {
    align-items: flex-end;
    display: flex;
    gap: 20px;
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
}
.MainBox-name-box-label-round {
    /*   color: #ffffff;
  font-family: "Prompt", Helvetica;
  font-size: 24px;
  font-weight: 700;
  height: 33px;
  letter-spacing: 0.15px;
  margin-top: -0.94px;
  position: relative; */
    background: linear-gradient(90deg, rgb(248, 202, 112) 0%, rgb(239, 207, 47) 49%, rgb(250, 193, 78) 100%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "Prompt", Helvetica;
    font-size: 32px;
    font-weight: 700;
    letter-spacing: 0.12px;
    line-height: 45px;
    margin-top: -1px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}
.MainBox-name-box-label {
    background: linear-gradient(90deg, rgba(240, 170, 34, 1) 0%, rgba(249, 219, 72, 1) 49%, rgba(240, 170, 34, 1) 100%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "Prompt", Helvetica;
    font-size: 32px;
    font-weight: 700;
    letter-spacing: 0.12px;
    line-height: 45px;
    margin-top: -1px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.MainBox-price-box-label {
    color: #f0b922;
    font-family: "Prompt", Helvetica;
    font-size: 24px;
    font-weight: 700;
    height: 33px;
    letter-spacing: 0.15px;
    margin-top: -0.94px;
    position: relative;
    text-shadow: #1c1100 0px 0px 10px;
}

.MainBox-description-label {
    color: #ffffff;
    font-family: "Prompt", Helvetica;
    font-size: 15.4px;
    font-weight: 400;
    letter-spacing: 0.08px;
    line-height: normal;
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.MainBox-box-image {
    margin: 0 auto;

    position: relative;
    -webkit-animation: shake1 1s ease-in-out infinite;
            animation: shake1 1s ease-in-out infinite;
}

.MainBox-how-to-work-label {
    align-items: center;
    display: flex;
    gap: 18px;
    justify-content: center;
    position: relative;
    width: 100%;
}
.MainBox-group {
    height: 8px;
    min-width: 439px;
    position: relative;
}
.MainBox-div-2 {
    height: 8px;
    position: relative;
    width: 439px;
}

.MainBox-line-12 {
    height: 2px;
    left: 0;
    position: absolute;
    top: 3px;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    width: 432px;
}
.MainBox-ellipse {
    background-color: #f0aa22;
    border-radius: 4px;
    height: 8px;
    left: 431px;
    position: absolute;
    top: 0;
    width: 8px;
}

@-webkit-keyframes shake1 {
    0% {
        -webkit-transform: translate(0);
                transform: translate(0);
    }
    10% {
        -webkit-transform: translate(-5px, -2px);
                transform: translate(-5px, -2px);
    }
    20% {
        -webkit-transform: translate(5px, 0);
                transform: translate(5px, 0);
    }
    30% {
        -webkit-transform: translate(-5px, -2px);
                transform: translate(-5px, -2px);
    }
    40% {
        -webkit-transform: translate(0);
                transform: translate(0);
    }
    50% {
        -webkit-transform: translate(0);
                transform: translate(0);
    }
    60% {
        -webkit-transform: translate(0);
                transform: translate(0);
    }
    70% {
        -webkit-transform: translate(0);
                transform: translate(0);
    }
    80% {
        -webkit-transform: translate(0);
                transform: translate(0);
    }
    90% {
        -webkit-transform: translate(0);
                transform: translate(0);
    }
    100% {
        -webkit-transform: translate(0);
                transform: translate(0);
    }
}

@keyframes shake1 {
    0% {
        -webkit-transform: translate(0);
                transform: translate(0);
    }
    10% {
        -webkit-transform: translate(-5px, -2px);
                transform: translate(-5px, -2px);
    }
    20% {
        -webkit-transform: translate(5px, 0);
                transform: translate(5px, 0);
    }
    30% {
        -webkit-transform: translate(-5px, -2px);
                transform: translate(-5px, -2px);
    }
    40% {
        -webkit-transform: translate(0);
                transform: translate(0);
    }
    50% {
        -webkit-transform: translate(0);
                transform: translate(0);
    }
    60% {
        -webkit-transform: translate(0);
                transform: translate(0);
    }
    70% {
        -webkit-transform: translate(0);
                transform: translate(0);
    }
    80% {
        -webkit-transform: translate(0);
                transform: translate(0);
    }
    90% {
        -webkit-transform: translate(0);
                transform: translate(0);
    }
    100% {
        -webkit-transform: translate(0);
                transform: translate(0);
    }
}

.MainBox-random-button {
    align-items: center;
    display: flex;
    gap: 52px;
    height: 47px;
    justify-content: center;
    position: relative;
    width: 262px;
    cursor: pointer;
    left: 50%;
    -webkit-transform: translate(-50%, 0px);
            transform: translate(-50%, 0px);
}

.MainBox-random-button-2 {
    align-items: center;
    display: flex;
    gap: 52px;
    height: 47px;
    justify-content: center;
    position: relative;
    width: 500px;
    cursor: pointer;
    left: 50%;
    -webkit-transform: translate(-50%, 0px);
            transform: translate(-50%, 0px);
}

.handle-MainBox-random-button-2 {
}

.Random-jigsaw-histories {
}

@media (max-width: 425px) {
    .handle-MainBox-random-button-2 {
        flex-direction: column;
        margin-top: 4rem;
    }
    .Random-jigsaw-histories {
        margin-top: 7rem;
    }
}

.MainBox-BG {
    height: 90px;
    object-fit: cover;
    width: 100%;
    position: absolute;
    top: calc(50% - 58px);
}

.MainBox-open-box-label {
    align-self: stretch;
    color: #1c1100;
    flex: 1 1;
    font-family: "Prompt", Helvetica;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.1px;
    line-height: 17.5px;
    margin-top: -1px;
    position: relative;
    text-align: center;
}

@media (max-width: 375px) {
    .MainBox-box-image {
        width: 60%;
    }
}

@media (min-width: 376px) and (max-width: 425px) {
    .MainBox-name-box-label {
        font-size: 18px;
    }
    .MainBox-box-image {
        width: 80%;
    }
    .congratulations-text {
        font-size: 2.5rem;
        right: 60px;
        top: -240px;
    }
}

@media (min-width: 426px) and (max-width: 768px) {
    .congratulations-text {
        font-size: 4rem;
        right: -65px;
        top: -305px;
    }
}

@media (min-width:769px) {
    .congratulations-text {
        right: -150px;
        top: -315px;
    }
}

@-webkit-keyframes scale2 {
    from {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
    to {
        -webkit-transform: scale(1.2);
                transform: scale(1.2);
    }
}

@keyframes scale2 {
    from {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
    to {
        -webkit-transform: scale(1.2);
                transform: scale(1.2);
    }
}
@-webkit-keyframes scale3 {
    from {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
    to {
        -webkit-transform: scale(1.05);
                transform: scale(1.05);
    }
}
@keyframes scale3 {
    from {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
    to {
        -webkit-transform: scale(1.05);
                transform: scale(1.05);
    }
}
