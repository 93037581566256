.custom-dropdown {
    position: relative;
    width: 100%;
    margin-bottom: 15px;
    border-radius: 4px;
}

.selected-option {
    display: flex;
    align-items: center;
    background-color: #000; /* match your theme */
    color: #fff;
    padding: 10px;
    border: 1px solid #fff;
    border-radius: 4px;
    cursor: pointer;
    min-height: 36px;
}

.selected-option img {
    margin-right: 10px;
    width: auto;
    height: 36px;
}

.options {
    position: absolute;
    width: 100%;
    background-color: #000;
    border: 1px solid #fff;
    border-top: none;
    z-index: 10;
    border-radius: 4px;
}

.option {
    display: flex;
    align-items: center;
    padding: 10px;
    transition: background-color 0.3s;
    cursor: pointer;
}

.option:hover {
    background-color: #333; /* darker shade for hover */
}

.option img {
    margin-right: 10px;
    width: auto;
    height: 36px;
}

.option:last-child {
    border-bottom: none;
    border-radius: 0 0 4px 4px;
}

/* Add a transition effect for opening/closing the dropdown */
.custom-dropdown .options {
    transition: height 0.3s ease;
}
