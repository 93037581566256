.truncate {
    width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.itembox-desktop-BG {
    height: 1646px;
    left: 1px;
    position: absolute;
    top: 666px;
    width: 100%;
}

.itembox-desktop-header-BG {
    height: 900px;
    left: -6px;
    object-fit: cover;
    position: absolute;
    top: -4px;
    width: calc(100% + 10px);
}

.itembox-desktop-live-drop-desktop {
    align-items: center;
    flex-direction: column;
    gap: 7px;
    position: relative;
}

.itembox-desktop-live-drop-heading {
    align-items: center;
    display: flex;
    gap: 6px;
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.itembox-desktop-live-icon {
    background-color: #ff0000;
    border-radius: 3.5px;
    height: 7px;
    min-width: 7px;
    position: relative;
}

.itembox-desktop-text-wrapper {
    color: #ffb500;
    font-family: "Prompt", Helvetica;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0.1px;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.itembox-desktop-live-drop-item {
    align-items: center;
    display: flex;
    flex-direction: column;
    min-height: 61px;
    padding: 0px 0px 0px 16px;
    position: relative;
    margin-bottom: 5px;
    opacity: 0;
    -webkit-transform: translateY(10px);
            transform: translateY(10px);
    -webkit-animation: fadeUp 0.5s ease-in-out forwards;
            animation: fadeUp 0.5s ease-in-out forwards;
}

@-webkit-keyframes fadeUp {
    from {
        opacity: 0;
        -webkit-transform: translateY(10px);
                transform: translateY(10px);
    }
    to {
        opacity: 1;
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
}

@keyframes fadeUp {
    from {
        opacity: 0;
        -webkit-transform: translateY(10px);
                transform: translateY(10px);
    }
    to {
        opacity: 1;
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
}

.itembox-desktop-frame-live-drop {
    -webkit-backdrop-filter: blur(79.48px) brightness(100%);
    backdrop-filter: blur(79.48px) brightness(100%);
    background: linear-gradient(0deg, rgba(243, 130, 0, 0.349) 0%, rgba(192, 109, 0, 0) 100%);
    border: 1px solid;
    border-color: #ff6d04;
    border-radius: 7.15px;
    height: calc(100% + 1px);
    left: 1px;
    top: -1px;
    width: calc(100% - 4px);
}

.itembox-desktop-rectangle {
    -webkit-backdrop-filter: blur(79.48px) brightness(100%);
    backdrop-filter: blur(79.48px) brightness(100%);
    background-color: #ff6d04;
    border-radius: 7.15px 0px 0px 7.15px;
    height: 62px;
    left: -1px;
    position: relative;
    top: -1px;
    width: 7px;
}

.itembox-desktop-live-drop-item-2 {
    -webkit-backdrop-filter: blur(79.48px) brightness(100%);
    backdrop-filter: blur(79.48px) brightness(100%);
    background: #262117;
    border: 1px solid;
    border-left: 10px solid #f0ab23;
    border-color: #f0ab23;
    border-radius: 7.15px;
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 1 1;
    gap: 10px;
    position: relative;
}

.itembox-desktop-item-live-drop-image {
    height: 50px;
    max-width: 70px;
    /*  position: absolute; */
    /*    right: 5px; */
    object-fit: contain;
}

.itembox-desktop-item-live-drop-label {
    display: flex;
    padding-left: 10px;
    align-items: flex-start;
    flex-direction: column;
    gap: 5px;
    justify-content: center;
}

.itembox-desktop-name-item-label {
    padding-right: 8px;
    min-width: 800px;
    overflow: hidden;
    text-overflow: ellipsis;
    background: linear-gradient(90deg, rgba(240, 170, 34, 1) 0%, rgba(249, 219, 72, 1) 49%, rgba(240, 170, 34, 1) 100%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "Prompt", Helvetica;
    font-size: 15.2px;
    font-weight: 700;
    letter-spacing: 0.08px;
}

@media (max-width: 850px) {
    .itembox-desktop-name-item-label {
        font-size: 9px !important;
    }
}

.itembox-desktop-frame {
    align-items: center;
    align-self: stretch;
    display: flex;
    gap: 7px;
    height: 24.64px;
    position: relative;
}

.itembox-desktop-icon-box {
    height: 24.64px;
    min-width: 25.43px;
    object-fit: cover;
    position: relative;
}

.itembox-desktop-box-label {
    width: 10px;
    /*overflow: hidden; 
  text-overflow: ellipsis; */
    white-space: nowrap;
    color: #ffffff;
    flex: 1 1;
    font-family: "Prompt", Helvetica;
    font-size: 13.9px;
    font-weight: 500;
    height: 12px;
    letter-spacing: 0.07px;
    line-height: 12.2px;
    position: relative;
}
.itembox-desktop-box-label-date {
    /*overflow: hidden; 
  text-overflow: ellipsis; */
    white-space: nowrap;
    color: #ffffff;
    flex: 1 1;
    padding: 3px 5px 3px 5px;
    border-radius: 10px;
    font-family: "Prompt", Helvetica;
    font-size: 10px;
    font-weight: 200;
    background-color: #ff0000;
    letter-spacing: 0.07px;
    line-height: 6px;
    position: absolute;
    top: -8px;
    z-index: 10;
    right: 0px;
}
.itembox-desktop-box-content {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 52px;
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.itembox-desktop-header-name-box {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 13px;
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.itembox-desktop-box-name-group {
    align-items: flex-end;
    display: flex;
    gap: 20px;
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.itembox-desktop-name-box-label {
    color: #ffffff;
    font-family: "Prompt", Helvetica;
    font-size: 30px;
    font-weight: 700;
    height: 33px;
    letter-spacing: 0.15px;
    line-height: normal;
    margin-top: -0.94px;
    position: relative;
    width: 232px;
}

.itembox-desktop-price-box-label {
    color: #f0b922;
    font-family: "Prompt", Helvetica;
    font-size: 28.1px;
    font-weight: 700;
    height: 33px;
    letter-spacing: 0.14px;
    line-height: normal;
    margin-top: -0.94px;
    position: relative;
    width: 129px;
}

.itembox-desktop-description-label {
    color: #ffffff;
    font-family: "Prompt", Helvetica;
    font-size: 15.4px;
    font-weight: 400;
    letter-spacing: 0.08px;
    line-height: normal;
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.itembox-desktop-box-image {
    height: 320px;
    min-width: 322px;
    position: relative;
}

.itembox-desktop-random-button {
    align-items: flex-start;
    display: flex;
    gap: 52px;
    justify-content: center;
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.itembox-desktop-img {
    height: 92px;
    left: calc(50% - 157px);
    object-fit: cover;
    position: absolute;
    top: calc(50% - 46px);
    width: 314px;
}

.itembox-desktop-open-box-label {
    color: #1c1100;
    font-family: "Prompt", Helvetica;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0.1px;
    line-height: 17.5px;
    margin-top: -1px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.itembox-desktop-iteminthebox-title {
    align-items: center;
    display: flex;
    gap: 69px;
    height: 151px;
    justify-content: center;
    position: relative;
    width: 100vw;
}

.itembox-desktop-graphic {
    height: 7.77px;
    min-width: 370.59px;
    position: relative;
}

.itembox-desktop-div-2 {
    height: 8px;
    position: relative;
    width: 371px;
}

.itembox-desktop-line-graph {
    height: 2px;
    left: 0;
    position: absolute;
    top: 3px;
    width: 365px;
}

.itembox-desktop-circle-graph {
    background-color: #f8d745;
    border-radius: 3.38px/3.89px;
    height: 8px;
    left: 364px;
    position: absolute;
    top: 0;
    width: 7px;
}

.itembox-desktop-header-label {
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent;
    background: linear-gradient(180deg, rgb(248, 215, 69) 0%, rgb(233.75, 161.4, 7.79) 100%);
    background-clip: text;
    color: transparent;
    font-family: "Prompt", Helvetica;
    font-size: 64px;
    font-weight: 700;
    left: 0;
    letter-spacing: 0.32px;
    line-height: 56.1px;
    position: absolute;
    text-align: center;
    text-fill-color: transparent;
    text-shadow: 0px 11.65px 11.65px #00000040;
    top: 0;
    white-space: nowrap;
}

.itembox-desktop-line-graph-2 {
    height: 2px;
    left: 0;
    position: absolute;
    top: 3px;
    -webkit-transform: rotate(-180deg);
            transform: rotate(-180deg);
    width: 365px;
}

.itembox-desktop-item-list-section {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 28px;
    justify-content: center;
    position: relative;
    width: 1438px;
}

.itembox-desktop-item-row {
    align-items: center;
    display: flex;
    gap: 31px;
    justify-content: center;
    margin-top: -4.5px;
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.itembox-desktop-box {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 26px;
    position: relative;
    width: 383px;
}

.itembox-desktop-box-image-2 {
    height: 380px;
    min-width: 383px;
    position: relative;
}

.itembox-desktop-text-wrapper-2 {
    align-self: stretch;
    color: #ffffff;
    font-family: "Prompt", Helvetica;
    font-size: 32px;
    font-weight: 700;
    letter-spacing: 0.16px;
    line-height: normal;
    position: relative;
    text-align: center;
}

.itembox-desktop-item-row-2 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 28px;
    position: relative;
    width: 864px;
}

.itembox-desktop-row {
    align-items: flex-end;
    display: flex;
    gap: 23.77px;
    height: 253px;
    position: relative;
    width: 837px;
}

.itembox-desktop-product-item-in-the-box-preview {
    overflow-y: hidden;
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 254px;
    justify-content: center;
    margin-top: -1px;
    position: relative;
    width: 198px;
}

.itembox-desktop-product-info {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 1 1;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    position: relative;
}

.itembox-desktop-price {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.itembox-desktop-price-label {
    color: #ffffff;
    font-family: "Prompt", Helvetica;
    font-size: 18px;
    font-weight: 500;
    height: 30px;
    letter-spacing: 0.09px;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    text-align: center;
    width: 148px;
}

.itembox-desktop-price-BG {
    height: 32px;
    left: calc(50% - 74px);
    object-fit: cover;
    position: absolute;
    top: calc(50% - 16px);
    width: 148px;
}

.itembox-desktop-description {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 78px;
    position: relative;
    width: 155px;
}

.itembox-desktop-product-name {
    color: #fff;
    letter-spacing: 0.07px;
    text-align: center;
    align-self: stretch;
    margin-top: -1.19px;
    font-family: Prompt, Helvetica;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    position: relative;
    height: 42px;
    -webkit-text-decoration: dotted;
            text-decoration: dotted;
}

.itembox-desktop-product-brand {
    align-self: stretch;
    font-family: "Prompt", Helvetica;
    font-size: 16.4px;
    font-weight: 500;
    height: 13px;
    letter-spacing: 0.08px;
    line-height: normal;
    position: relative;
    text-align: center;
}

.itembox-desktop-BG-line-wrapper {
    background: linear-gradient(180deg, rgba(255, 204.39, 0, 0) 0%, rgba(255, 209.87, 27.63, 0.11) 100%);
    border-radius: 5.94px;
    height: 227px;
    left: calc(50% - 88px);
    position: absolute;
    top: calc(50% - 114px);
    width: 176px;
}

.itembox-desktop-BG-line {
    border-radius: 5.94px;
    height: 7px;
    position: relative;
    top: 220px;
}

.itembox-desktop-article {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 254px;
    justify-content: center;
    margin-right: -26.31px;
    margin-top: -1px;
    position: relative;
    width: 198px;
}

.itembox-desktop-item-row-3 {
    align-items: flex-end;
    display: flex;
    gap: 23.77px;
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.itembox-desktop-product-item-in-the-box-preview-2 {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 254px;
    justify-content: center;
    position: relative;
    width: 198px;
}

.itembox-desktop-item-row-4 {
    align-items: flex-end;
    display: flex;
    gap: 23.77px;
    margin-bottom: -4.5px;
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}
