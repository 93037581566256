.invitefriend-desktop-invitefriend-desktop {
  align-items: center;
  background-color: #131313;
  border: 1px none;
  display: flex;
  flex-direction: column;
  height: 1123px;
  min-width: 100vw;
  /* overflow-x: hidden; */
  position: relative;
}

.invitefriend-desktop-BG {
  height: 860px;
  left: -3px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
}

.invitefriend-desktop-content-section {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 860px;
  justify-content: center;
  padding: 78px 0px 20px;
  position: relative;
  width: 100vw;
}

.invitefriend-desktop-banner {
  background-image: url(../../../public/img/inviteFriend/bg.gif);
  background-position: 50% 50%;
  background-size: cover;
  height: 297px;
  min-width: 1140px;
  position: relative;
}

.invitefriend-desktop-banner-label {
  color: transparent;
  font-family: "Prompt", Helvetica;
  font-size: 42px;
  font-weight: 700;
  left: 83px;
  letter-spacing: 0.21px;
  line-height: 36.8px;
  position: absolute;
  top: 100px;
}

.invitefriend-desktop-text-wrapper {
  color: #ffffff;
}

.invitefriend-desktop-span {
  color: #febd16;
}

.invitefriend-desktop-invite-friend {
  height: 460px;
  min-width: 1140px;
  position: relative;
}

.invitefriend-desktop-container {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 60px;
  left: 54px;
  position: relative;
  top: 53px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.invitefriend-desktop-blur-BG {
  -webkit-backdrop-filter: blur(100px) brightness(100%);
  backdrop-filter: blur(100px) brightness(100%);
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.01) 0%, rgba(0, 0, 0, 0.01) 100%);
  height: 460px;
  left: -54px;
  position: absolute;
  top: -60px;
  width: 1140px;
}

.invitefriend-desktop-topic {
  align-items: center;
  display: flex;
  gap: 28.31px;
  position: relative;
  width: 1033px;
}

.invitefriend-desktop-left {
  height: 6.66px;
  min-width: 365.51px;
  position: relative;
}

.invitefriend-desktop-div {
  height: 7px;
  position: relative;
  width: 366px;
}

.invitefriend-desktop-line {
  height: 2px;
  left: 0;
  position: absolute;
  top: 2px;
  width: 360px;
}

.invitefriend-desktop-ellipse {
  background-color: #febd16;
  border-radius: 3.33px;
  height: 7px;
  left: 359px;
  position: absolute;
  top: 0;
  width: 7px;
}

.invitefriend-desktop-invite-title {
  align-items: center;
  display: flex;
  gap: 16px;
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.invitefriend-desktop-invite-label {
  color: #febd16;
  font-family: "Prompt", Helvetica;
  font-size: 32px;
  font-weight: 700;
  letter-spacing: 0.16px;
  line-height: 28.1px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.invitefriend-desktop-right {
  height: 6.66px;
  margin-right: -8.64px;
  min-width: 365.51px;
  position: relative;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.invitefriend-desktop-line-12 {
  height: 2px;
  left: 0;
  position: absolute;
  top: 3px;
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
  width: 360px;
}

.invitefriend-desktop-invite-info {
  align-items: flex-start;
  display: flex;
  gap: 78px;
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.invitefriend-desktop-code-invite-friend {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 25px;
  position: relative;
  width: 465px;
}

.invitefriend-desktop-code-invite-label {
  color: #ffffff;
  font-family: "Prompt", Helvetica;
  font-size: 23px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 27.6px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.invitefriend-desktop-code-box {
  height: 63px;
  margin-right: -2px;
  min-width: 467px;
  position: relative;
}

.invitefriend-desktop-overlap-group {
  border: 0.5px solid;
  border-color: #ffffff;
  border-radius: 7px;
  height: 63px;
  position: relative;
  width: 465px;
}

.invitefriend-desktop-code-label {
  color: #ffffff;
  font-family: "Prompt", Helvetica;
  font-size: 32px;
  font-weight: 400;
  left: 26px;
  letter-spacing: 0;
  line-height: 38.4px;
  position: absolute;
  text-align: center;
  top: 12px;
  white-space: nowrap;
  width: 361px;
}

.invitefriend-desktop-icon-copy {
  height: 30px;
  left: 414px;
  position: absolute;
  top: 16px;
  width: 30px;
}

.invitefriend-desktop-howto-invite-friend {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 34px;
  position: relative;
  width: 490px;
}

.invitefriend-desktop-text-wrapper-2 {
  color: #febd16;
  font-family: "Prompt", Helvetica;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 28.8px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.invitefriend-desktop-text-wrapper-3 {
  color: #ffffff;
  font-family: "Prompt", Helvetica;
  font-size: 20px;
  font-weight: 400;
  height: 52px;
  letter-spacing: 0;
  line-height: 24px;
  position: relative;
  width: 490px;
}

.invitefriend-desktop-history {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 14px;
  position: relative;
  width: 1033px;
}

.invitefriend-desktop-div-2 {
  align-items: center;
  display: flex;
  gap: 10px;
  position: relative;
  width: 1030px;
}

.invitefriend-desktop-text-wrapper-4 {
  color: #ffffff;
  font-family: "Prompt", Helvetica;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: 343px;
}

.invitefriend-desktop-text-wrapper-5 {
  color: #ffffff;
  font-family: "Prompt", Helvetica;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  margin-right: -19px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: 343px;
}

.invitefriend-desktop-text-wrapper-6 {
  color: #8d8d8d;
  font-family: "Prompt", Helvetica;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: 343px;
}

.invitefriend-desktop-text-wrapper-7 {
  color: #8d8d8d;
  font-family: "Prompt", Helvetica;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  margin-right: -19px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: 343px;
}

.invitefriend-desktop-text-wrapper-8 {
  align-self: stretch;
  color: #ffffff80;
  font-family: "Prompt", Helvetica;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  position: relative;
  text-align: center;
  white-space: nowrap;
}
