.tag{
    display: none;
}

.autBox {
    background: rgb(40, 219, 194);
    background: linear-gradient(135deg,
    rgba(255, 170, 11, 0.4) 0%,
    rgb(47, 41, 33) 20%,
    rgb(29, 26, 23) 50%,
    rgb(20, 20, 19) 100%);
}

.bg-signin{
    background: linear-gradient(90deg, rgba(240,170,34,1) 3%, rgba(245,206,61,1) 25%, rgba(251,227,80,1) 50%, rgba(243,198,54,1) 75%, rgba(240,170,34,1) 100%);
}