.shipping-process-desktop-content-section {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 32px;
    height: 1093px;
    padding: 101px 0px 0px;
    position: relative;
    width: 100vw;
}
.shipping-process-desktop-address-detail-back-btn {
    left: -10px;
    position: relative;
    top: 20px;
    color: white;
    font-size: 18px;
    background-color: #131313;
    width: 110px;
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
}
.shipping-process-desktop-address-detail-back-btn:hover {
    background-color: #2e2e2e;
}
.shipping-process-desktop-shipping-process-desktop {
    align-items: center;
    background-color: #131313;
    border: 1px none;
    display: flex;
    flex-direction: column;
    height: 1356px;
    min-width: 100vw;
    position: relative;
}

.shipping-process-desktop-BG {
    height: 1038px;
    left: 1px;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: calc(100% - 1px);
}

.shipping-process-desktop-delivery-title {
    align-items: center;
    display: flex;
    gap: 28.31px;
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.shipping-process-desktop-vecter {
    height: 6.66px;
    min-width: 365.51px;
    position: relative;
}

.shipping-process-desktop-div {
    height: 7px;
    position: relative;
    width: 366px;
}

.shipping-process-desktop-line {
    height: 2px;
    left: 0;
    position: absolute;
    top: 2px;
    width: 360px;
}

.shipping-process-desktop-ellipse {
    background-color: #febd16;
    border-radius: 3.33px;
    height: 7px;
    left: 359px;
    position: absolute;
    top: 0;
    width: 7px;
}

.shipping-process-desktop-icon-delivery {
    height: 39.18px;
    min-width: 39.18px;
    position: relative;
}

.shipping-process-desktop-title {
    height: 28px;
    min-width: 162px;
    position: relative;
}

.shipping-process-desktop-label {
    color: #febd16;
    font-family: "Prompt", Helvetica;
    font-size: 32px;
    font-weight: 700;
    left: 0;
    letter-spacing: 0.16px;
    line-height: 28.1px;
    position: absolute;
    text-align: center;
    top: 0;
    white-space: nowrap;
}

.shipping-process-desktop-overlap-wrapper {
    height: 6.66px;
    min-width: 365.51px;
    position: relative;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

.shipping-process-desktop-line-12 {
    height: 2px;
    left: 0;
    position: absolute;
    top: 3px;
    -webkit-transform: rotate(-180deg);
            transform: rotate(-180deg);
    width: 360px;
}

.shipping-process-desktop-body-section {
    align-items: flex-start;
    display: flex;
    gap: 15px;
    margin-bottom: -92px;
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    flex-direction: row;
}

.shipping-process-desktop-address-detail {
    -webkit-backdrop-filter: blur(100px) brightness(100%);
    backdrop-filter: blur(100px) brightness(100%);
    background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.767) 100%,
    rgba(0, 0, 0, 0.716) 100%
  );
    border-radius: 10px;
    max-height: 700px;
    width: 659px;
    position: relative;
}

.shipping-process-desktop-address-detail-2 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 21px;
    height: 754px;
    left: 31px;
    position: relative;
    top: 29px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.shipping-process-desktop-title-address-detail-label {
    color: #ffb500;
    font-family: "Montserrat", Helvetica;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.shipping-process-desktop-address-detail-form {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 10px;
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.shipping-process-desktop-div-2 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 8px;
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.shipping-process-desktop-text-wrapper {
    color: #ffffff;
    font-family: "Prompt", Helvetica;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.shipping-process-desktop-input {
    border: 0.5px solid;
    border-color: #ffffff;
    border-radius: 7px; 
}

.shipping-process-desktop-input-2 {
    border: 0.5px solid;
    border-color: #ffffff;
    border-radius: 7px;
    height: 100px;
    min-width: 578px;
    position: relative;
}

.shipping-process-desktop-address-detail-3 {
    align-items: flex-start;
    display: flex;
    gap: 48px;
    position: relative;
    width: 578px;
}

.shipping-process-desktop-input-3 {
    border: 0.5px solid;
    border-color: #ffffff;
    border-radius: 7px;
    height: 40px;
    min-width: 265px;
    position: relative;
}

.shipping-process-desktop-button-group {
    align-items: center;
    display: flex;
    gap: 312px;
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.shipping-process-desktop-backward-button {
    align-items: flex-end;
    display: flex;
    gap: 14px;
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    cursor: pointer;
}

.shipping-process-desktop-iconsax-linear-arrowleft {
    height: 24px;
    min-width: 24px;
    position: relative;
}

.shipping-process-desktop-text-wrapper-2 {
    color: #ffb500;
    font-family: "Prompt", Helvetica;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.shipping-process-desktop-pay-shipping-button {
    align-items: center;
    background: linear-gradient(90deg, rgba(240,170,34,1) 3%, rgba(245,206,61,1) 25%, rgba(251,227,80,1) 50%, rgba(243,198,54,1) 75%, rgba(240,170,34,1) 100%);
    border-radius: 24px;
    display: flex;
    gap: 10px;
    justify-content: center;
    padding: 10px 20px;
    position: relative;
    width: 208px;
    cursor: pointer;
}

.shipping-process-desktop-text-wrapper-3 {
    color: #883f00;
    font-family: "Prompt", Helvetica;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -0.71px;
    position: relative;
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.shipping-process-desktop-dlivery-product-list {
    -webkit-backdrop-filter: blur(100px) brightness(100%);
    backdrop-filter: blur(100px) brightness(100%);
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.892) 0%, rgba(0, 0, 0, 0.968) 100%);
    border-radius: 10px;
    max-height: 700px;
    width: 463px;
    position: relative;
}

.shipping-process-desktop-dlivery-product-list-2 {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 25px;
    height: 795px;
    position: relative;
    top: 18px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.shipping-process-desktop-dlivery-product-list-3 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 379px;
    justify-content: center;
    position: relative;
}

.shipping-process-desktop-list {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 336px;
    position: relative;
    width: 444px;
    max-height: 336px;
    overflow-y: auto;
}

.shipping-process-desktop-list::-webkit-scrollbar {
    width: 6px;
}

.shipping-process-desktop-list::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 3px;
}

.shipping-process-desktop-list::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.1);
}

.shipping-process-desktop-dlivery-product-list-label {
    color: #ffffff;
    font-family: "Montserrat", Helvetica;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.shipping-process-desktop-line-18 {
    height: 1px;
    min-width: 463px;
    object-fit: cover;
    position: relative;
}

.shipping-process-desktop-frame {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 1 1;
    flex-direction: column;
    gap: 30px;
    justify-content: center;
    position: relative;
}

.shipping-process-desktop-frame-175 {
    align-items: flex-start;
    display: flex;
    gap: 187px;
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.shipping-process-desktop-text-wrapper-8 {
    color: #ffffff;
    font-family: "Montserrat", Helvetica;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: 92px;
}

.shipping-process-desktop-frame-2 {
    align-items: flex-start;
    display: flex;
    gap: 47px;
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.shipping-process-desktop-text-wrapper-9 {
    color: #ffffff;
    font-family: "Montserrat", Helvetica;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: 11px;
}

.shipping-process-desktop-text-wrapper-10 {
    color: #ffffff;
    font-family: "Montserrat", Helvetica;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: 29px;
}

.shipping-process-desktop-frame-176 {
    align-items: flex-start;
    display: flex;
    gap: 205px;
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.shipping-process-desktop-text-wrapper-11 {
    color: #ffffff;
    font-family: "Montserrat", Helvetica;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: 55px;
}

.shipping-process-desktop-text-wrapper-12 {
    color: #ffffff;
    font-family: "Montserrat", Helvetica;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: 32px;
}

.shipping-process-desktop-frame-177 {
    align-items: flex-start;
    display: flex;
    gap: 157px;
    position: relative;
    width: 368px;
}

.shipping-process-desktop-text-wrapper-13 {
    color: #ffffff;
    font-family: "Montserrat", Helvetica;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: 103px;
}

.shipping-process-desktop-frame-174 {
    align-items: flex-start;
    display: flex;
    gap: 47px;
    position: relative;
    width: 108px;
}

.shipping-process-desktop-remark {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 8px;
    height: 136px;
    justify-content: center;
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.shipping-process-desktop-text-wrapper-14 {
    color: #ecc834;
    font-family: "Prompt", Helvetica;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.shipping-process-desktop-input-4 {
    border: 0.31px solid;
    border-color: #ffffff;
    border-radius: 4.36px;
    height: 94px;
    min-width: 383px;
    position: relative;
}

@media screen and (max-width: 1170px) {
    .shipping-process-desktop-body-section {
        flex-direction: column;
    }
    .shipping-process-desktop-address-detail {
        max-width: 100vw;
    }
}

@media screen and (max-width: 700px) {
    .shipping-process-desktop-body-section {
        width: 100vw;
        align-items: center;
    }
    .shipping-process-desktop-address-detail {
        width: 90%;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
    }
    .shipping-process-desktop-address-detail-2 {
        width: 80%;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
    }
    .shipping-process-desktop-dlivery-product-list {
        width: 90vw;
    }
    .shipping-process-desktop-dlivery-product-list-2 {
        width: 90%;
    }
    .shipping-process-desktop-frame-176 {
        gap: 20vw;
    }
}
