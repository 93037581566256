.jigsaw-product {
    border: 9px solid #050503;
    border-radius: 8px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    z-index: 10;
}

.jigsaw-board-position {
    text-align: -webkit-center;
}
