.table-auto > tbody > tr > td {
    padding: 0.75rem;
    vertical-align: top;
    border: 1px solid #e2e8f0;
}
/* Add this to your CSS file */
.table-container {
    width: 100%;
    overflow-x: auto; /* Enable horizontal scrolling */
    max-width: 100%;
}

.table-wrapper {
    overflow-y: auto; /* Enable vertical scrolling */
    max-height: 100%; /* Set a max height to limit vertical scrolling */
}
.table-auto {
    margin-left: auto;
    margin-right: auto;
}
.table-auto > thead > tr > th {
    padding: 0.75rem;
    vertical-align: top;
    border: 1px solid #e2e8f0;
}
.history-popup {
    border-radius: 12.796px;
    border: 2.559px solid rgba(95, 118, 242, 0.41);
    background: rgba(9, 12, 15, 0.892);
    -webkit-backdrop-filter: blur(63.98176574707031px);
            backdrop-filter: blur(63.98176574707031px);
    max-width: 1075px;
    width: 90%;
    height: 90vh;
    position: fixed;
    /* center */
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 20px 40px 20px;
    z-index: 1000;
}
.history-popup-bg {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.804);
    z-index: 999;
}
.history-popup-detail {
    color: #fff;
    text-align: center;
    font-family: Prompt;
    font-size: 23.033px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.115px;
    text-transform: lowercase;
}
.history-popup > .history-popup-header {
    text-align: center;
    text-shadow: 0px 0px 42.654510498046875px rgba(255, 216, 79, 0.28);
    font-family: Prompt;
    font-size: 42.655px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.213px;
    text-transform: uppercase;

    background: linear-gradient(90deg, #f0aa22 5.03%, #f5ce3d 28.13%, #fbe350 53.68%, #f3c636 77.27%, #f0aa22 99.39%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.history-popup-btn {
    display: flex;
    width: 307.506px;
    height: 84.456px;
    padding: 22.738px 50.888px 22.738px 50.892px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 77.959px;
    border: 1.083px solid #fffa78;
    background: linear-gradient(90deg, #f0aa22 5.03%, #f5ce3d 28.13%, #fbe350 53.68%, #f3c636 77.27%, #f0aa22 99.39%);
    box-shadow: 0px 0px 20.5726px 5.41384px rgba(251, 255, 33, 0.28);
}
.history-popup-btn:hover {
    scale: 1.02;
}
.history-popup-btn > div {
    color: #734700;
    text-align: center;
    font-family: Prompt;
    font-size: 25.986px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
