#how-to-play {
    width: 100vw;
}

.home-desk-image-slide {
    margin-left: 2rem;
}

@media (min-width: 326px) and (max-width: 425px) {
    .home-desk-image-slide {
        margin-left: 3rem;
    }
}

@media (min-width: 426px) and (max-width: 768px) {
    .home-desk-image-slide {
        margin-left: -1.5rem;
    }
}

@media (min-width: 769px) {
    .home-desk-image-slide {
        margin-left: -2.5rem;
    }
}



.home-desk-home-desk {
    align-items: center;
    background: linear-gradient(
        153deg,
        rgb(8, 7, 5) 0%,
        rgb(15, 11, 4) 16%,
        rgb(15, 12, 6) 45%,
        rgb(13, 11, 6) 74%,
        rgb(6, 5, 3) 100%
    );
    border: 1px none;
    display: flex;
    flex-direction: column;
    /* width: 100vw; */
    position: relative;
    /*overflow-x: hidden;*/
    /*overflow-y: hidden;*/
    overflow: hidden;
}

.home-desk-navbar {
    align-items: center;
    display: flex;
    gap: 542px;
    padding: 8px 41px;
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.home-desk-logo {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    position: relative;
    width: 170px;
}

.home-desk-mystery-boxes {
    color: #ffb500;
    font-family: "Prompt", Helvetica;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0.1px;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.home-desk-element {
    color: #ffffff;
    font-family: "Prompt", Helvetica;
    font-size: 8.9px;
    font-weight: 700;
    letter-spacing: 0.04px;
    line-height: normal;
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.home-desk-menu {
    align-items: center;
    display: flex;
    gap: 47px;
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.home-desk-home-button {
    align-items: flex-end;
    display: flex;
    gap: 5px;
    position: relative;
    width: 82px;
}

.home-desk-iconsax-linear-box {
    height: 23px;
    min-width: 23px;
    position: relative;
}

.home-desk-text-wrapper {
    color: #febd16;
    flex: 1 1;
    font-family: "Prompt", Helvetica;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.07px;
    line-height: normal;
    position: relative;
}

.home-desk-howto-button {
    align-items: center;
    display: flex;
    gap: 10px;
    position: relative;
    width: 71px;
}

.home-desk-icon-information {
    height: 22px;
    min-width: 22px;
    position: relative;
}

.home-desk-div {
    color: #ffffff;
    flex: 1 1;
    font-family: "Prompt", Helvetica;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.07px;
    line-height: normal;
    margin-top: -0.5px;
    position: relative;
}

.home-desk-login-button {
    align-items: center;
    background: linear-gradient(180deg, rgb(40, 220, 194) 0%, rgb(66, 134, 222) 53.16%, rgb(133, 83, 242) 100%);
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    gap: 10px;
    justify-content: center;
    padding: 12px 20px;
    position: relative;
    transition: all 0.2s ease;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.home-desk-text-wrapper-2 {
    color: #ffffff;
    font-family: "Prompt", Helvetica;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.08px;
    line-height: 14px;
    margin-top: -1px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.home-desk-hero-section {
    background-image: url(../../../../public/img/vibrance-1.gif);
    background-size: 100% 100%;
    min-height: 1000px;
    width: 100vw;
    background-size: cover;
    position: relative;
    left: 0;
    -webkit-mask-image: linear-gradient(to top, transparent 15%, black 30%);
            mask-image: linear-gradient(to top, transparent 15%, black 30%);
}

.home-desk-hero-content {
    width: 90vw;
    flex-direction: column;
    align-items: flex-start;
    gap: 18px;
    display: flex;
    position: relative;
    top: 111px;
    left: 210px;
}
@media (max-width: 1160px) {
    .home-desk-hero-content {
        width: 90vw;
        flex-direction: column;
        align-items: flex-start;
        gap: 18px;
        display: flex;
        position: relative;
        top: 111px;
        left: 40px;
    }
}

.home-desk-h-1 {
    color: #0000;
    height: 206px;
    letter-spacing: 0.32px;
    width: 100%;
    margin-top: -1px;
    font-family: Prompt, Helvetica;
    font-size: 64px;
    font-weight: 700;
    line-height: normal;
    -webkit-animation: 2s ease-in-out infinite wave;
            animation: 2s ease-in-out infinite wave;
    position: relative;
}

@media (max-width: 768px) {
    .home-desk-h-1 {
        font-size: 64px;
        /* height: 206; */
    }
}

/* mobile media */
@media (max-width: 670px) {
    .home-desk-h-1 {
        font-size: 45px;
    }
}
@media (max-width: 465px) {
    .home-desk-h-1 {
        font-size: 40px;
    }
}
@media (max-width: 375px) {
    .home-desk-h-1 {
        font-size: 35px;
        height: auto;
    }
}

@media (max-width: 320px) {
    .home-desk-h-1 {
        font-size: 24px;
    }
}

.home-desk-span {
    color: #ffffff;
}

.home-desk-text-wrapper-3 {
    color: #ffb500;
}

@-webkit-keyframes wave {
    0% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
    50% {
        -webkit-transform: translateY(-5px);
                transform: translateY(-5px);
    }
    100% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
}

@keyframes wave {
    0% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
    50% {
        -webkit-transform: translateY(-5px);
                transform: translateY(-5px);
    }
    100% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
}

.home-desk-sub-hero-content {
    -webkit-backdrop-filter: blur(100px) brightness();
    backdrop-filter: blur(100px) brightness();
    max-width: 752px;
    width: 85vw;
    background: linear-gradient(#00000021 0%, #00000003 100%);
    border: 2px solid #0000;
    border-image: linear-gradient(#0047ff00, #71bbff00) 1;
    border-radius: 29px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    padding: 30px 41px 30px 35px;
    display: flex;
    position: relative;
}

@media (max-width: 600px) {
    .home-desk-sub-hero-content {
        top: -50px;
    }
}

.home-desk-frame {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    display: flex;
    position: relative;
}
.home-desk-supreme-off-white-BAPE-apple {
    color: #ffffffbd;
    letter-spacing: 0;
    width: 100%;
    margin-top: -1px;
    font-family: Prompt, Helvetica;
    font-size: 27.4px;
    font-weight: 400;
    line-height: normal;
    position: relative;
}

.home-desk-group-button {
    align-items: flex-start;
    display: flex;
    gap: 17px;
    position: relative;
    width: 100%;
}

.home-desk-random-button {
    cursor: pointer;
    height: 49.76px;
    width: 250px;
    position: relative;
    transition: all 0.2s ease;
}
.home-desk-random-button:hover {
    scale: 1.02;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}
.home-desk-overlap-group {
    background: linear-gradient(
        90deg,
        rgba(240, 170, 34, 1) 3%,
        rgba(245, 206, 61, 1) 25%,
        rgba(251, 227, 80, 1) 50%,
        rgba(243, 198, 54, 1) 75%,
        rgba(240, 170, 34, 1) 100%
    );
    border-radius: 25px;
    height: 50px;
    position: relative;
    width: 100%;
    padding: 10px;
    font-family: "Prompt", Helvetica;
    font-size: 26.6px;
    font-weight: 500;
    letter-spacing: 0.13px;
    line-height: 23.3px;
    color: #734700;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.home-desk-random-label {
    color: #734700;
    font-family: "Prompt", Helvetica;
    font-size: 26.6px;
    font-weight: 500;
    letter-spacing: 0.13px;
    line-height: 23.3px;
    position: absolute;
    text-align: center;
    top: 12px;
    white-space: nowrap;
    width: 100%;
}

.home-desk-overlap-wrapper {
    cursor: pointer;
    height: 50px;
    margin-right: -2px;
    width: 250px;
    position: relative;
    transition: all 0.2s ease;
    font-family: "Prompt", Helvetica;
    font-size: 26.6px;
    font-weight: 500;
    letter-spacing: 0.13px;
    line-height: 23.3px;
    color: #734700;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
.home-desk-overlap-wrapper:hover {
    scale: 1.02;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}
.home-desk-overlap {
    background-color: #2b2a34;
    border: 0.71px solid;
    border-color: #febd17;
    border-radius: 25px;
    height: 50px;
    position: relative;
    width: 100%;
    font-family: "Prompt", Helvetica;
    font-size: 26.7px;
    font-weight: 500;
    letter-spacing: 0.13px;
    line-height: 69.6px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #febd17;
}

@media (max-width: 375px) and (min-width: 320px) {
    .home-desk-overlap-group,
    .home-desk-overlap {
        font-size: 18px;
    }
}

.item-box-flex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    max-width: 90vw;
    /* height: 100%; */
    padding: 0 20px;
    border: 3px solid #a87c01;
    border-radius: 29px;
    background: rgb(77, 69, 50);
    background: linear-gradient(167deg, rgb(15, 13, 9) 0%, rgb(4, 3, 2) 35%, rgb(4, 3, 1) 100%);
}
.home-desk-random-label-2 {
    display: flex;
    color: #febd17;
    font-family: "Prompt", Helvetica;
    font-size: 26.7px;
    font-weight: 500;
    height: 26px;
    letter-spacing: 0.13px;
    line-height: 69.6px;
    position: relative;
    text-align: center;
    top: -10px;
    white-space: nowrap;
}
.home-desk-content-section {
    width: 100vw;
    background-position: 50%;
    background-size: cover;
    margin-top: -50px;
    padding-bottom: 348px;
    position: relative;
}

.home-desk-content-flex {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 40px;
    left: 0px;
    position: relative;
    top: 69px;
    width: 100vw;
}

.home-desk-how-to-work-label {
    align-items: center;
    display: flex;
    gap: 18px;
    justify-content: center;
    position: relative;
    width: 100%;
    margin-bottom: 40px;
    margin-top: 40px;
}

.home-desk-group {
    height: 8px;
    min-width: 439px;
    position: relative;
}

.home-desk-div-2 {
    height: 8px;
    position: relative;
    width: 439px;
}

.home-desk-line {
    height: 2px;
    left: 0;
    position: absolute;
    top: 3px;
    width: 432px;
}
/*  mobile*/
@media (max-width: 548px) {
    .home-desk-line {
        display: none;
        width: 0px;
    }
}
.home-desk-ellipse {
    background-color: #f0aa22;
    border-radius: 4px;
    height: 8px;
    left: 431px;
    position: absolute;
    top: 0;
    width: 8px;
}

.home-desk-how-it-work {
    background: linear-gradient(90deg, rgba(240, 170, 34, 1) 0%, rgba(249, 219, 72, 1) 49%, rgba(240, 170, 34, 1) 100%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "Prompt", Helvetica;

    font-weight: 700;
    letter-spacing: 0.12px;
    line-height: 45px;
    margin-top: -1px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.home-desk-group-100 {
    height: 8px;
    width: 440px;
    position: relative;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

.home-desk-line-12 {
    height: 2px;
    left: 0;
    position: absolute;
    top: 3px;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    width: 432px;
}
/*  mobile*/
@media (max-width: 548px) {
    .home-desk-group {
        width: 0px;
        display: none;
    }
    .home-desk-group-100 {
        width: 0px;
        display: none;
    }
    .home-desk-div-2 {
        width: 0px;
    }
    .home-desk-line-12 {
        display: none;
    }
}

@media (max-width: 1020px) {
    .home-desk-group {
        width: 0px;
        display: none;
    }
    .home-desk-group-100 {
        width: 0px;
        display: none;
    }
    .home-desk-div-2 {
        width: 0px;
    }
    .home-desk-line-12 {
        display: none;
    }
}

.home-desk-how-to-work-image {
    /* height: 213px; */
    width: 90vw;
    object-fit: contain;
    position: relative;
    margin: 0 auto;
}
.home-desk-how-to-work-image:hover {
    scale: 1.01;
}

.home-desk-item-list {
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    padding: 10px;
    display: flex;
    position: relative;
}
@media (max-width: 548px) {
    .home-desk-item-list {
        width: 215px;
    }
}
.home-category-button {
    width: 138.06px;
    height: 116px;
    flex-shrink: 0;
    border-radius: 21.682px;
    border: 1px solid #fffa78;
    background: black;
    -webkit-backdrop-filter: blur(17.81610679626465px);
            backdrop-filter: blur(17.81610679626465px);
    color: #fff;
    font-family: Prompt;
    font-size: 13.918px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.slider-nav-next {
    opacity: 0;
}
.slider-nav-prev {
    opacity: 0;
}
.slider-bullets {
    opacity: 0;
}
.slider-wrapper {
    background: #0000 !important;
}
.slider-wrapper li.active {
    opacity: 100 !important;
}
.slider-wrapper li {
    overflow: visible !important;
    opacity: 0.1 !important;
}

.home-category-button.active {
    scale: 1.05;
    background: linear-gradient(180deg, rgba(240, 170, 34, 0.37) 0%, rgba(240, 170, 34, 0) 100%);
}
.home-category-button > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 11.284px;
    flex-shrink: 0;
}
.home-category-button > img {
    width: 55.824px;
    flex-shrink: 0;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5px;
    padding-top: 20px;
}
.home-desk-item-product {
    max-height: 326px;
    min-width: 230px;
    position: relative;
    margin: 50px;
}

.home-desk-overlap-group-2 {
    height: 326px;
    position: relative;
    width: 230px;
    margin-left: -25px;
}

.home-desk-BG {
    -webkit-backdrop-filter: blur(100px) brightness(100%);
    backdrop-filter: blur(100px) brightness(100%);
    background: rgb(4, 3, 3);
    background: linear-gradient(
        144deg,
        rgba(124, 97, 40, 0.156) 0%,
        rgba(24, 19, 8, 0.238) 49%,
        rgba(rgba(20, 6, 6, 0.282) 1) 100%
    );
    height: 108px;
    left: 0;
    position: absolute;
    top: 164px;
    width: 230px;
}

.home-desk-product-image {
    height: 149px;
    left: 14px;
    object-fit: contain;
    position: absolute;
    top: 0;
    width: 200px;
    scale: 1.54;
}

.home-desk-product-name {
    color: #ffffff;
    font-family: "Prompt", Helvetica;
    font-size: 20px;
    font-weight: 600;
    height: 26px;
    left: 12px;
    letter-spacing: 0.1px;
    line-height: 17.5px;
    position: absolute;
    text-align: center;
    line-height: 1.3;
    top: 185px;
    width: 208px;
}

.home-desk-price-BG {
    height: 91px;
    left: 22px;
    object-fit: cover;
    position: absolute;
    top: 225px;
    width: 198px;
}

.home-desk-price-label {
    color: #603a02;
    font-family: "Prompt", Helvetica;
    font-size: 20px;
    font-weight: 600;
    height: 46px;
    left: 44px;
    letter-spacing: 0.1px;
    line-height: 17.5px;
    position: absolute;
    text-align: center;
    top: 267px;
    width: 153px;
}

.home-desk-footer-col {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 26px;
    height: 60.11px;
    position: relative;
    width: 112px;
}

.home-desk-title {
    align-self: stretch;
    color: #ffffff;
    font-family: "Prompt", Helvetica;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.08px;
    line-height: 14px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
}

.home-desk-sub-menu-list {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: -0.89px;
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.home-desk-facebook-menu {
    align-items: center;
    display: flex;
    gap: 10px;
    position: relative;
    width: 232.3px;
}

.home-desk-icon-facebook {
    height: 21px;
    min-width: 21px;
    position: relative;
}

.home-desk-text-wrapper-4 {
    color: #808080;
    font-family: "Prompt", Helvetica;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0.08px;
    line-height: 14px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.home-desk-infomations {
    color: #ffffff;
    font-family: "Prompt", Helvetica;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.08px;
    line-height: 14px;
    margin-top: -1px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: 114px;
}

.home-desk-frame-302 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    margin-bottom: -1.48px;
    position: relative;
}

.home-desk-text-wrapper-5 {
    color: #808080;
    font-family: "Prompt", Helvetica;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0.08px;
    line-height: 14px;
    margin-top: -1px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.home-desk-footer-col-3 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 26px;
    height: 101px;
    position: relative;
    width: 200px;
}

.home-desk-payment {
    color: #ffffff;
    font-family: "Prompt", Helvetica;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.08px;
    line-height: 14px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: 114px;
}

.home-desk-frame-2 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    position: relative;
}

@media screen and (max-width: 767px) {
    .home-desk-content-section {
        padding-bottom: 80px;
    }
}

@media screen and (max-width: 738px) {
    .home-desk-hero-sectionP {
        min-height: 400px;
    }
}

@media screen and (max-width: 480px) {
    .home-desk-random-button,
    .home-desk-overlap-group,
    .home-desk-overlap-wrapper,
    .home-desk-overlap {
        width: 100%;
    }
}
@media screen and (max-width: 400px) {
    .home-desk-content-section {
        margin-top: -100px;
    }
    .home-desk-supreme-off-white-BAPE-apple {
        font-size: 16px;
    }
}
@media screen and (max-width: 425px) {
    .home-desk-footer-col-3 {
        display: none;
    }
    .home-desk-hero-section {
        min-height: auto;
    }
    .home-desk-product-image {
        scale: 1;
    }
    .home-desk-item-product {
        /* max-width: auto !important;
        max-height: auto !important; */
        margin: auto;
    }
}
