/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

.selection-selection-wrapper {
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.selection-product-info {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 23px;
  height: 167px;
  left: -12px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.selection-product-image {
  align-self: stretch;
  object-fit: contain;
  height: 58.44px;
  min-width: 130px;
  position: relative;
}

.selection-description {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 6.57px;
  height: 51.21px;
  position: relative;
  width: 101.77px;
}

.selection-product-name {
  align-self: stretch;
  color: #ffffff;
  font-family: "Prompt", Helvetica;
  font-size: 9.2px;
  font-weight: 500;
  letter-spacing: 0.05px;
  line-height: normal;
  margin-top: -0.78px;
  position: relative;
  text-align: center;
}

.selection-product-brand {
  align-self: stretch;
  font-family: "Prompt", Helvetica;
  font-size: 10.8px;
  font-weight: 500;
  height: 8.54px;
  letter-spacing: 0.05px;
  line-height: normal;
  position: relative;
  text-align: center;
}

.slider-wrapper {
  width: 400000px;
  height: 220px;
  background: #00000060;
  display: inline-block;
  position: relative;
  right: 0;
}

.AD34-selection-BG {
  background: linear-gradient(
    180deg,
    rgba(255, 204.39, 0, 0) 0%,
    rgba(255, 209.87, 27.63, 0.11) 100%
  );
  border-radius: 3.9px;
  height: 149px;
  position: absolute;
  width: 115px;
  top: 10px;
  left: 40px;
  z-index: 1;
}

.AD34-selection-BG-line {
  border-radius: 3.9px;
  height: 5px;
  width: 115px;
  position: absolute;
  top: 150px;
  z-index: 2;
}

.swiper-slide {
  width: 200px;
  height: 167px;
  margin-top: 30px;
  display: inline-block;
  margin-left: 0px;
  overflow: hidden;
  border-left: #5e5e5e 1px solid;
  /* background: rgb(199, 36, 36); */
}
.middle {
  width: 5px;
  height: 284px;
  background: #9b6002;
  margin-left: calc(100vw / 2);
  position: absolute;
  top: 190px;
  box-shadow: 0 0 20px 2px #000;
  -webkit-animation: scaleVertical 0.2s ease-in-out infinite alternate;
          animation: scaleVertical 0.2s ease-in-out infinite alternate;
  -webkit-transform-origin: center;
          transform-origin: center;
}

@-webkit-keyframes scaleVertical {
  from {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
  to {
    -webkit-transform: scaleY(1.05);
            transform: scaleY(1.05);
  }
}

@keyframes scaleVertical {
  from {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
  to {
    -webkit-transform: scaleY(1.05);
            transform: scaleY(1.05);
  }
}

.layout {
  width: 25%;
  height: 200px;
  margin: 0 auto;
}

.gold {
  background: gold;
}
.red {
  background: red;
}
.dark-purple {
  background: deeppink;
}
.purple {
  background: fuchsia;
}
.blue {
  background: blue;
}
a {
  color: #eeeeee;
  font-size: 1rem;
}
