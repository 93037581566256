.jigsaw-image{
    display: flex;
    
  }
.filter-yellow {
  -webkit-filter: drop-shadow(30px 30px 30px #e5bb336b);
          filter: drop-shadow(30px 30px 30px #e5bb336b);
}

.jigsaw-text {
  font-size: 32px;
}

@media (max-width:768px) {
  .jigsaw-text{
    font-size: 16px;
  }
}