.drop-down-menu-drop-down-menu-wrapper {
  background-color: #0d0d0d;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: absolute;
  width: 100%; 
 /*   right: 30px; */
  top: 50px;
  z-index: 100;
}

.drop-down-menu-drop-down-menu {
  border: 1px none;
  height: 100%;
}

.drop-down-menu-menu-list {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 22px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.navbar-profile-dropdown {
  left: calc(100vw - 340px);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 4px;
  justify-content: center;
  align-items: center ;
  gap :10px ;
  margin-right: -6px;
  padding: 12px 20px;
  display: flex;
  position: absolute;
  top: 0px;
}

@media (max-width:375px) {
  .navbar-profile-dropdown{
   scale: 0.75;
   left: auto;
   right: -30px;
  }
  
}

@media (min-width:376px) and (max-width:425px) {
  .navbar-profile-dropdown{
    left: auto;
    scale: 0.75;
    right: -20px;
  }
  
}

@media (min-width:426px) and (max-width:768px) {
  .navbar-profile-dropdown{
    left: auto;
    scale: 0.75;
    right: -20px;
  }
  
}

.drop-down-menu-dropdown-menu {
  align-items: center;
  align-self: stretch;
  display: flex;
  gap: 20px;
  position: relative;
  cursor: pointer;
}

.drop-down-menu-dropdown-menu:hover .drop-down-menu-text-wrapper {
  color: #ffb500;
}

.drop-down-menu-dropdown-admin {
  align-items: center;
  align-self: stretch;
  display: flex;
  gap: 15px;
  position: relative;
  cursor: pointer;
}

.drop-down-menu-dropdown-admin:hover .drop-down-menu-text-wrapper {
  color: #ffb500;
}


.drop-down-menu-icon-star {
  height: 24px;
  min-width: 24px;
  position: relative;
}

.drop-down-menu-icon-admin {
  height: 28px;
  width: 28px;
  position: relative;
}

.drop-down-menu-text-wrapper {
  color: #606060;
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
