.navbar-navbar {
    z-index: 1;
    -webkit-backdrop-filter: blur(100px) brightness(100%);
    backdrop-filter: blur(100px) brightness(100%);
    background: rgb(64, 45, 11);
    background: linear-gradient(144deg, rgba(64, 45, 11, 1) 0%, rgba(57, 40, 11, 1) 49%, rgba(32, 23, 7, 1) 100%);
    border: 1px solid;
    border-color: #8d7539;

    align-items: center;
    gap: 542px;
    padding: 8px 41px;
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

.navbar-logo {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    position: relative;
    width: 170px;
    cursor: pointer;
}

@media (max-width: 425px) {
    .navbar-logo {
        scale: 0.75;
        left: -54px;
    }
}

@media (max-width: 380px) {
    .navbar-login-button {
        left: calc(100vw - 157px);
    }
}

.navbar-mystery-boxes {
    color: #ffb500;
    font-family: "Prompt", Helvetica;
    font-size: 25px;
    font-weight: 700;
    letter-spacing: 0.1px;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.navbar-element {
    color: #ffffff;
    font-family: "Prompt", Helvetica;
    font-size: 8.9px;
    font-weight: 700;
    letter-spacing: 0.04px;
    line-height: normal;
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.navbar-menu {
    justify-content: center;
    align-items: center;
    gap: 47px;
    display: flex;
    position: relative;
    left: -417px;
}
/* mobile media */
@media (max-width: 760px) {
    .navbar-menu {
        display: none;
    }
}

@media (min-width:426px) and (max-width: 768px) {
    .navbar-menu {
        scale: 0.75;
        left: -470px;
    }

    .navbar-logo{
        scale: 0.75;
    }
    
}

.navbar-home-button {
    align-items: flex-end;
    display: flex;
    gap: 5px;
    margin-left: -99px;
    position: relative;
    width: 82px;
}

.navbar-raffle-button {
    align-items: center;
    display: flex;
    gap: 10px;
    justify-content: center;
    margin-right: -145px;
    position: absolute;
    width: 160px;
    cursor: pointer;
}

.navbar-iconsax-linear-box {
    height: 23px;
    min-width: 23px;
    position: relative;
}
.navbar-profile-name {
    color: white;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    gap: 10px;
    width: 100px;
}
.navbar-profile-name > svg {
    margin-top: 5px;
}
.navbar-element-3337-12968 {
    color: #febd16;
    flex: 1 1;
    font-family: "Prompt", Helvetica;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.07px;
    line-height: normal;
    position: relative;
    cursor: pointer;
}
.navbar-element-3337-12968:hover {
    color: #febd16;
}
.navbar-howto-button {
    align-items: center;
    display: flex;
    gap: 10px;
    justify-content: center;
    margin-right: -450px;
    position: absolute;
    width: 120px;
    cursor: pointer;
}

.navbar-howto-button:hover {
    color: #febd16;
}

.howto-button-link-container {
    display: flex;
}

.raffle-button-link-container {
    display: flex;
}

.navbar-element-3337-12973 {
    color: #ffffff;
    flex: 1 1;
    font-family: "Prompt", Helvetica;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.07px;
    line-height: normal;
    margin-top: 0.5px;
    padding-top: 1px;
    margin-left: 5px;
    position: relative;
}

.navbar-iconsax-linear-infocircle {
    background-size: contain;
    width: 22px;
    height: 22px;
    margin-right: 3px;
}

.jigsaw {
    background-size: contain;
    width: 22px;
    height: 22px;
    margin-right: 3px;
}

.howto-button-link-container:hover .navbar-element-3337-12973 {
    color: #febd16;
}

.raffle-button-link-container:hover .navbar-element-3337-12973 {
    color: #febd16;
}

.howto-button-link-container:hover .navbar-iconsax-linear-infocircle {
    content: url("../../../../public/img/ItemBoxDesktop/iconsax-linear-infocircle-5-hover.png");
}

.raffle-button-link-container:hover .jigsaw {
    content: url("../../../../public/img/ItemBoxDesktop/jigsaw-hover.png");
}

.navbar-login-button {
    cursor: pointer;
    width: -moz-fit-content;
    width: -webkit-fit-content;
    width: fit-content;
    background: linear-gradient(
        90deg,
        rgba(240, 170, 34, 1) 3%,
        rgba(245, 206, 61, 1) 25%,
        rgba(251, 227, 80, 1) 50%,
        rgba(243, 198, 54, 1) 75%,
        rgba(240, 170, 34, 1) 100%
    );
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-right: -6px;
    padding: 12px 20px;
    transition: box-shadow 0.3s ease-in-out;
    display: flex;
    position: absolute;
    left: calc(100vw - 136px);
}
.navbar-login-button:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Add a shadow effect on hover */
    scale: 1.03;
}

.navbar-element-3337-13086 {
    color: #734700;
    font-family: "Prompt", Helvetica;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.08px;
    line-height: 14px;
    margin-top: -1px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.nav-bar-div {
    background-color: #111215;
    border: 0.5px solid;
    border-color: #b1b1b1;
    border-radius: 5px;
    height: 36px;
    -webkit-column-gap: 8px;
            column-gap: 8px;
    padding: 0px 8px;
    display: flex;
    align-items: center;
}

.nav-bar-icon {
    height: 21px;
    left: 0;
    top: 2px;
}

.nav-bar-coin-label {
    color: #ebab3c;
    font-family: "Montserrat", Helvetica;
    font-size: 14px;
    font-weight: 500;
    left: 0;
    letter-spacing: 0;
    line-height: normal;
    top: 10px;
    cursor: pointer;
}

.nav-bar-icon-add-coin {
    width: 24px;
    cursor: pointer;
}
/*Header navbar font change whe low resolutions*/
@media screen and (max-width: 360px) {
    .navbar-mystery-boxes {
        font-size: 20px;
    }
}
