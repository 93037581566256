.shipping-success-desktop-shipping-success-desktop {
  align-items: center;
  background-color: #131313;
  border: 1px none;
  display: flex;
  flex-direction: column;
  height: 1189px;
  min-width: 100vw;
  position: relative; 
  color: #fff;
  background-size: cover;
}

.shipping-success-desktop-BG {
  height: 1189px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
    width: 100vw; 
}

.shipping-success-desktop-content-section {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 30px;
  height: 100%;
  padding: 104px 0px;
  position: relative;
/*   width: 100vw; */
}

.shipping-success-desktop-product-get {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: center;
  margin-bottom: -152px;
  position: relative;
  width: 100vw; 
}

.shipping-success-desktop-product-get-title {
  align-items: center;
  display: flex;
  gap: 28.31px;
  position: absolute;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  top: -20px;
  overflow: hidden;
}

.shipping-success-desktop-vector {
  height: 6.66px;
  min-width: 365.51px;
  position: relative;
  display: contents;
}

.shipping-success-desktop-div {
  height: 7px;
  position: relative;
  width: 366px;
}

.shipping-success-desktop-line {
  height: 2px;
  left: 0;
  position: absolute;
  top: 2px;
  width: 360px;
}

.shipping-success-desktop-ellipse {
  background-color: #febd16;
  border-radius: 3.33px;
  height: 7px;
  left: 359px;
  position: absolute;
  top: 0;
  width: 7px;
}

.shipping-success-desktop-title {
  align-items: center;
  display: flex;
  gap: 16px;
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.shipping-success-desktop-iconsax-linear-truckfast {
  height: 33px;
  min-width: 33px;
  position: relative;
}

.shipping-success-desktop-group {
  height: 28px;
  margin-right: -2px;
  min-width: 220px;
  position: relative;
}

.shipping-success-desktop-text-wrapper {
  color: #febd16;
  font-family: "Prompt", Helvetica;
  font-size: 32px;
  font-weight: 700;
  left: 0;
  letter-spacing: 0.16px;
  line-height: 28.1px;
  position: absolute;
  text-align: center;
  top: 0;
  white-space: nowrap;
}

.shipping-success-desktop-overlap-wrapper {
  height: 6.66px;
  min-width: 365.51px;
  position: relative;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.shipping-success-desktop-line-12 {
  height: 2px;
  left: 0;
  position: absolute;
  top: 3px;
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
  width: 360px;
}
.shipping-success-desktop-lists {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 30px; 
  /* height: 750px; */
/*    width: 100vw;   */
  position: relative; 
  overflow-y: scroll;    
  top: 50px; 

   /*  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  gap: 12.02px;
  height: 458px;
  padding: 2px 0px;
  position: relative;
  width: 991px;
  overflow-y: scroll;  */
}
.shipping-success-desktop-lists-2 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 30px; 
  height: 750px;
/*    width: 100vw;   */
  position: relative;   
  top: 50px; 

   /*  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  gap: 12.02px;
  height: 458px;
  padding: 2px 0px;
  position: relative;
  width: 991px;
  overflow-y: scroll;  */
}

.shipping-success-desktop-lists::-webkit-scrollbar {
  width: 10px; /* Set width of scrollbar */
}

.shipping-success-desktop-lists::-webkit-scrollbar-track {
  background-color: #080101; /* Set color of scrollbar track */
}

.shipping-success-desktop-lists::-webkit-scrollbar-thumb {
  background-color: #888; /* Set color of scrollbar thumb */
  border-radius: 5px; /* Set border radius of scrollbar thumb */
}

.shipping-success-desktop-lists::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Set color of scrollbar thumb on hover */
}

@media screen and (max-width : 1150px ) {
  .shipping-success-desktop-lists {
    align-items: flex-start;
  }
  .shipping-success-desktop-vector{
    display: none;
  }
  .shipping-success-desktop-overlap-wrapper{
    display: none;
  }
}
@media screen and (max-width : 1151px ) {
  .shipping-success-desktop-lists {
   overflow-y: scroll; 
   overflow-x: hidden; 
  }
}
