.jigsaw-inventory-card {
    display: flex;
    flex-direction: column;
    position: relative;
    min-width: 100px;
    max-width: 150px;
}
.jigsaw-inventory-card > .jigsaw-inventory-card-image > img {
    width: 100%;
    object-fit: contain;
}
.jigsaw-inventory-card > .jigsaw-inventory-card-round {
    width: 100px;
    height: 24px;
    flex-shrink: 0;
    border-radius: 17px;
    background: #f0aa22;
    position: absolute;
    z-index: 3;
    top: -12px;
    left: 10px;
}
.jigsaw-inventory-card > .jigsaw-inventory-card-round > p {
    color: #fff;
    font-family: Prompt;
    font-size: 15.111px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.076px;
    text-transform: uppercase;
    padding-left: 10px;
}
.jigsaw-inventory-card > .jigsaw-inventory-card-name {
    text-shadow: 0px 0px 27.64670181274414px rgba(255, 216, 79, 0.28);
    font-family: Prompt;
    font-size: 27.647px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.138px;
    text-transform: uppercase;
    text-align: center;

    background: linear-gradient(90deg, #f0aa22 5.03%, #f5ce3d 28.13%, #fbe350 53.68%, #f3c636 77.27%, #f0aa22 99.39%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
