.search-dialog {
    border-radius: 10px;
    border: 2px solid rgba(95, 118, 242, 0.41);
    background: rgba(9, 12, 15, 0.57);
    -webkit-backdrop-filter: blur(50px);
            backdrop-filter: blur(50px);
    /* opacity: 0; */
    transition: all 0.3s ease-in-out;
    -webkit-animation: fadeIn 0.3s ease-in-out;
            animation: fadeIn 0.3s ease-in-out;
    overflow-y: scroll;
    margin-left: 20%;
    margin-right: 20%;
    margin-top: 5%;
    margin-bottom: 5%;
}

@media (max-width: 425px) {
    .search-dialog {
        margin-left: 10%;
        margin-right: 10%;
    }
}
@media (max-width: 768px) {
    .search-dialog {
        margin-left: 15%;
        margin-right: 15%;
    }
}

.title-dialog {
    background: linear-gradient(90deg, #f0aa22 5.03%, #f5ce3d 28.13%, #fbe350 53.68%, #f3c636 77.27%, #f0aa22 99.39%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: 0px 0px 33.33333206176758px rgba(255, 216, 79, 0.28);
    font-family: Prompt;
    font-size: 33.333px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.167px;
    text-transform: uppercase;
}

@media (min-width: 376px) and (max-width: 425px) {
    .title-dialog {
        font-size: 26.333px;
    }
}

@media (max-width: 375px) {
    .title-dialog {
        font-size: 18.333px;
    }
}

input[type="search"] {
    border: none;
    background: transparent;
    margin: 0;
    padding: 7px 8px;
    font-size: 14px;
    color: inherit;
    border: 1px solid transparent;
    border-radius: inherit;
}

input[type="search"]::-webkit-input-placeholder {
    color: #bbb;
}

input[type="search"]::placeholder {
    color: #bbb;
}

.search-input {
    width: 100%;
    max-width: 715px;
    height: 60px;
    flex-shrink: 0;
    border-radius: 10px;
    background: rgba(1, 0, 1, 0.5);
}

.input-search-style {
    border: none;
    outline: none;
    --tw-ring-color: none !important;
    --tw-ring-offset-shadow: none;
    --tw-ring-shadow: none;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
    border-color: transparent !important;
}

input::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    color: white;
}

input::placeholder {
    /* Most modern browsers support this now. */
    color: white;
}

.search-not-found {
    color: #fff;
    font-family: Prompt;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.09px;
    text-transform: lowercase;
}

.title-dialog-user {
    color: #fff;
    font-family: Prompt;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.09px;
    text-transform: capitalize;
}

.subtitle-dialog-jigsaw-pieces {
    color: #f9e251;
    font-family: Prompt;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.09px;
    text-transform: capitalize;
}
