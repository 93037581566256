.home-desk-home-desk {
    align-items: center;
    background-color: #000000;
    border: 1px none;
    display: flex;
    flex-direction: column;
    position: relative;
}

.home-desk-navbar {
    align-items: center;
    background-color: #00000066;
    display: flex;
    gap: 542px;
    padding: 8px 41px;
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.home-desk-logo {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    position: relative;
    width: 170px;
}

.home-desk-mystery-boxes {
    color: #ffb500;
    font-family: "Prompt", Helvetica;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0.1px;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.home-desk-element {
    color: #ffffff;
    font-family: "Prompt", Helvetica;
    font-size: 8.9px;
    font-weight: 700;
    letter-spacing: 0.04px;
    line-height: normal;
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.home-desk-menu {
    align-items: center;
    display: flex;
    gap: 47px;
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.home-desk-home-button {
    align-items: flex-end;
    display: flex;
    gap: 5px;
    position: relative;
    width: 82px;
}

.home-desk-iconsax-linear-box {
    height: 23px;
    min-width: 23px;
    position: relative;
}

.home-desk-text-wrapper {
    color: #febd16;
    flex: 1 1;
    font-family: "Prompt", Helvetica;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.07px;
    line-height: normal;
    position: relative;
}

.home-desk-howto-button {
    align-items: center;
    display: flex;
    gap: 10px;
    position: relative;
    width: 71px;
}

.home-desk-icon-information {
    height: 22px;
    min-width: 22px;
    position: relative;
}

.home-desk-div {
    color: #ffffff;
    flex: 1 1;
    font-family: "Prompt", Helvetica;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.07px;
    line-height: normal;
    margin-top: -0.5px;
    position: relative;
}

.home-desk-login-button {
    align-items: center;
    background: linear-gradient(180deg, rgb(40, 220, 194) 0%, rgb(66, 134, 222) 53.16%, rgb(133, 83, 242) 100%);
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    gap: 10px;
    justify-content: center;
    padding: 12px 20px;
    position: relative;
    transition: all 0.2s ease;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.home-desk-text-wrapper-2 {
    color: #ffffff;
    font-family: "Prompt", Helvetica;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.08px;
    line-height: 14px;
    margin-top: -1px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.home-desk-hero-section {
    background-image: url(../../../../../public/img/vibrance-1.gif);
    background-size: 100% 100%;
    height: 693px;
    min-width: 100vw;
    position: relative;
    left: 0;
}

.home-desk-span {
    color: #ffffff;
}

.home-desk-text-wrapper-3 {
    color: #ffb500;
}

.home-desk-random-button {
    cursor: pointer;
    height: 49.76px;
    width: 178px;
    position: relative;
    transition: all 0.2s ease;
}

.home-desk-overlap-group {
    background: linear-gradient(180deg, rgb(40, 219, 194) 0%, rgb(67, 131, 222) 52.6%, rgb(136, 80, 243) 96.88%);
    border-radius: 11.39px;
    height: 50px;
    position: relative;
    width: 176px;
}

.home-desk-overlap-wrapper {
    cursor: pointer;
    height: 50px;
    margin-right: -2px;
    width: 172.71px;
    position: relative;
    transition: all 0.2s ease;
}

.home-desk-overlap {
    background-color: #2b2a34;
    border: 0.71px solid;
    border-color: #d2d2d2;
    border-radius: 11.44px;
    height: 50px;
    position: relative;
    width: 171px;
}

/* .home-desk-random-label-2 {
  color: #ffffff;
  font-family: "Prompt", Helvetica;
  font-size: 26.7px;
  font-weight: 500;
  height: 26px;
  left: 16px;
  letter-spacing: 0.13px;
  line-height: 69.6px;
  position: absolute;
  text-align: center;
  top: -10px;
  white-space: nowrap;
  width: 136px;
} */

.home-desk-content-flex {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 40px;
    left: 240px;
    position: relative;
    top: 69px;
    width: 100vw;
}

.home-desk-how-to-work-label {
    align-items: center;
    display: flex;
    gap: 18px;
    justify-content: center;
    position: relative;
    width: 100%;
}

.home-desk-group {
    height: 8px;
    min-width: 439px;
    position: relative;
}

.home-desk-div-2 {
    height: 8px;
    position: relative;
    width: 439px;
}

.home-desk-line {
    height: 2px;
    left: 0;
    position: absolute;
    top: 3px;
    width: 432px;
}

.home-desk-ellipse {
    background-color: #60fff5;
    border-radius: 4px;
    height: 8px;
    left: 431px;
    position: absolute;
    top: 0;
    width: 8px;
}

.home-desk-how-it-work {
    color: #5febff;
    font-family: "Prompt", Helvetica;
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 0.12px;
    line-height: 21px;
    margin-top: -1px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.home-desk-line-12 {
    height: 2px;
    left: 0;
    position: absolute;
    top: 3px;
    -webkit-transform: rotate(-180deg);
            transform: rotate(-180deg);
    width: 432px;
}

.home-desk-item-list {
    align-items: flex-start;
    display: flex;
    gap: 10px;
    justify-content: center;
    padding: 10px;
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.home-desk-item-list:hover {
    -webkit-filter: drop-shadow(0px 0px 30px #fcd03f6b);
            filter: drop-shadow(0px 0px 30px #fcd03f6b);
}

.home-desk-item-product {
    height: 326px;
    min-width: 100%;
    position: relative;
    -webkit-filter: drop-shadow(0px 0px 30px #e5bb336b);
            filter: drop-shadow(0px 0px 30px #e5bb336b);
}

.home-desk-overlap-group-2 {
    height: 326px;
    position: relative;
    width: 230px;
    left: 30px;
}

.home-desk-item-list {
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    padding: 10px;
    display: flex;
    position: relative;
}

.home-desk-BG {
    -webkit-backdrop-filter: blur(100px) brightness(100%);
    backdrop-filter: blur(100px) brightness(100%);
    background: linear-gradient(180deg, rgba(9.77, 1.71, 1.71, 0.26) 0%, rgba(0, 0, 0, 0.55) 100%);
    border: 2px solid;
    border-color: transparent;
    border-image: linear-gradient(to bottom, rgba(0, 71.4, 255, 0), rgba(112.62, 186.66, 255, 0)) 1;
    border-radius: 29px;
    height: 108px;
    left: 0;
    position: absolute;
    top: 164px;
    width: 230px;
}

.home-desk-product-image {
    height: 203px;
    left: 19px;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 204px;
}

.home-desk-product-name {
    color: #ffffff;
    font-family: "Prompt", Helvetica;
    font-size: 20px;
    padding-top: 10px;
    height: 26px;
    left: 12px;
    letter-spacing: 0.1px;
    line-height: 17.5px;
    position: absolute;
    text-align: center;
    top: 218px;
    width: 208px;
}

.home-desk-price-BG {
    height: 91px;
    left: 22px;
    object-fit: cover;
    position: absolute;
    top: 235px;
    width: 210px;
}

.home-desk-price-label {
    color: #0b0101;
    font-family: "Prompt", Helvetica;
    font-size: 20px;
    font-weight: 500;
    height: 46px;
    left: 44px;
    letter-spacing: 0.1px;
    line-height: 17.5px;
    position: absolute;
    text-align: center;
    top: 274px;
    width: 153px;
}
.item-desktop-price-label {
    color: #fdf563;
    font-family: "Prompt", Helvetica;
    font-size: 20px;
    font-weight: 500;
    height: 46px;
    left: 44px;
    letter-spacing: 0.1px;
    line-height: 17.5px;
    position: absolute;
    text-align: center;
    top: 274px;
    width: 153px;
}

.home-desk-footer-col {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 26px;
    height: 60.11px;
    position: relative;
}

.home-desk-title {
    align-self: stretch;
    color: #ffffff;
    font-family: "Prompt", Helvetica;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.08px;
    line-height: 14px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
}

.home-desk-sub-menu-list {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: -0.89px;
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.home-desk-facebook-menu {
    align-items: center;
    display: flex;
    gap: 10px;
    position: relative;
    width: 232.3px;
}

.home-desk-icon-facebook {
    height: 21px;
    min-width: 21px;
    position: relative;
}

.home-desk-text-wrapper-4 {
    color: #808080;
    font-family: "Prompt", Helvetica;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0.08px;
    line-height: 14px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.home-desk-infomations {
    color: #ffffff;
    font-family: "Prompt", Helvetica;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.08px;
    line-height: 14px;
    margin-top: -1px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: 114px;
}

.home-desk-frame-302 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    margin-bottom: -1.48px;
    position: relative;
}

.home-desk-text-wrapper-5 {
    color: #808080;
    font-family: "Prompt", Helvetica;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0.08px;
    line-height: 14px;
    margin-top: -1px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.home-desk-footer-col-3 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 26px;
    height: 101px;
    position: relative;
    width: 213.75px;
}

.home-desk-payment {
    color: #ffffff;
    font-family: "Prompt", Helvetica;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.08px;
    line-height: 14px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: 114px;
}

.home-desk-frame-2 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    position: relative;
}
