.itemrandom-desktop-itemrandom-desktop {
  align-items: center;
  background-color: #131313;
  border: 1px none;
  display: flex;
  flex-direction: column;
  height: 2312px;
  min-width: 100vw;
  position: relative;
}

.itemrandom-desktop-header-section {
  align-items: flex-start;
  display: flex;
  gap: 10px;
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  opacity: 0;
  -webkit-animation: fadeIn 0.5s ease-in-out forwards;
          animation: fadeIn 0.5s ease-in-out forwards;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.itemrandom-desktop-BG-header {
  height: 600px;
  left: -6px;
  object-fit: cover;
  position: absolute;
  top: -6px;
  width: calc(100% + 12px);
}

.itemrandom-desktop-product-ramdom {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 50px;
  height: 588px;
  padding: 84px 0px;
  position: relative;
  width: 100vw;
}

.itemrandom-desktop-random {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: -27.91px;
  margin-left: -78.7px;
  margin-right: -78.7px;
  padding: 0px 74px;
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.itemrandom-desktop-item-random {
  align-items: center;
  display: flex;
  gap: 13px;
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.itemrandom-desktop-product-item-in-the-box-preview {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 166.77px;
  justify-content: center;
  position: relative;
  width: 130px;
}

.itemrandom-desktop-product-info {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 1 1;
  flex-direction: column;
  gap: 23px;
  justify-content: center;
  position: relative;
}

.itemrandom-desktop-product-image {
  align-self: stretch;
  height: 58.44px;
  min-width: 130px;
  position: relative;
}

.itemrandom-desktop-description {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 6.57px;
  height: 51.21px;
  position: relative;
  width: 101.77px;
}

.itemrandom-desktop-product-name {
  align-self: stretch;
  color: #ffffff;
  font-family: "Prompt", Helvetica;
  font-size: 9.2px;
  font-weight: 500;
  letter-spacing: 0.05px;
  line-height: normal;
  margin-top: -0.78px;
  position: relative;
  text-align: center;
}

.itemrandom-desktop-product-brand {
  align-self: stretch;
  color: #ecc834;
  font-family: "Prompt", Helvetica;
  font-size: 10.8px;
  font-weight: 500;
  height: 8.54px;
  letter-spacing: 0.05px;
  line-height: normal;
  position: relative;
  text-align: center;
}

.itemrandom-desktop-BG {
  background: linear-gradient(
    180deg,
    rgba(255, 204.39, 0, 0) 0%,
    rgba(255, 209.87, 27.63, 0.11) 100%
  );
  border-radius: 3.9px;
  height: 149px;
  left: calc(50% - 57px);
  position: absolute;
  top: calc(50% - 75px);
  width: 115px;
}

.itemrandom-desktop-BG-line {
  background-color: #ecc834;
  border-radius: 3.9px;
  height: 5px;
  position: relative;
  top: 145px;
}

.itemrandom-desktop-article {
  align-items: center;
  box-shadow: 0px 0px 33.17px #ffe064;
  display: flex;
  flex-direction: column;
  height: 206.79px;
  justify-content: center;
  position: relative;
  width: 161.2px;
}

.itemrandom-desktop-div {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 1 1;
  flex-direction: column;
  gap: 28.53px;
  justify-content: center;
  position: relative;
}

.itemrandom-desktop-img {
  align-self: stretch;
  height: 72.46px;
  min-width: 161.2px;
  position: relative;
}

.itemrandom-desktop-description-2 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 8.14px;
  height: 63.5px;
  position: relative;
  width: 126.19px;
}

.itemrandom-desktop-text-wrapper {
  align-self: stretch;
  color: #ffffff;
  font-family: "Prompt", Helvetica;
  font-size: 11.4px;
  font-weight: 500;
  letter-spacing: 0.06px;
  line-height: normal;
  margin-top: -0.97px;
  position: relative;
  text-align: center;
}

.itemrandom-desktop-product-brand-2 {
  align-self: stretch;
  color: #ecc834;
  font-family: "Prompt", Helvetica;
  font-size: 13.4px;
  font-weight: 500;
  height: 10.58px;
  letter-spacing: 0.07px;
  line-height: normal;
  position: relative;
  text-align: center;
}

.itemrandom-desktop-BG-line-wrapper {
  background: linear-gradient(
    180deg,
    rgba(255, 204.39, 0, 0) 0%,
    rgba(255, 209.87, 27.63, 0.11) 100%
  );
  border-radius: 4.84px;
  height: 185px;
  left: calc(50% - 71px);
  position: absolute;
  top: calc(50% - 93px);
  width: 143px;
}

.itemrandom-desktop-BG-line-2 {
  background-color: #ecc834;
  border-radius: 4.84px;
  height: 6px;
  position: relative;
  top: 180px;
}

.itemrandom-desktop-product-item-in-the-box-preview-2 {
  align-items: center;
  box-shadow: 0px 0px 50px #ffe064;
  display: flex;
  flex-direction: column;
  height: 311.73px;
  justify-content: center;
  position: relative;
  width: 243px;
}

.itemrandom-desktop-product-info-2 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 1 1;
  flex-direction: column;
  gap: 43px;
  justify-content: center;
  position: relative;
}

.itemrandom-desktop-product-image-2 {
  align-self: stretch;
  height: 109.23px;
  min-width: 243px;
  position: relative;
}

.itemrandom-desktop-description-3 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 12.27px;
  height: 95.73px;
  position: relative;
  width: 190.23px;
}

.itemrandom-desktop-p {
  align-self: stretch;
  color: #ffffff;
  font-family: "Prompt", Helvetica;
  font-size: 17.2px;
  font-weight: 500;
  letter-spacing: 0.09px;
  line-height: normal;
  margin-top: -1.46px;
  position: relative;
  text-align: center;
}

.itemrandom-desktop-product-brand-3 {
  align-self: stretch;
  color: #ecc834;
  font-family: "Prompt", Helvetica;
  font-size: 20.2px;
  font-weight: 500;
  height: 15.95px;
  letter-spacing: 0.1px;
  line-height: normal;
  position: relative;
  text-align: center;
}

.itemrandom-desktop-div-wrapper {
  background: linear-gradient(
    180deg,
    rgba(255, 204.39, 0, 0) 0%,
    rgba(255, 209.87, 27.63, 0.11) 100%
  );
  border-radius: 7.29px;
  height: 279px;
  left: calc(50% - 107px);
  position: absolute;
  top: calc(50% - 139px);
  width: 215px;
}

.itemrandom-desktop-BG-line-3 {
  background-color: #ecc834;
  border-radius: 7.29px;
  height: 8px;
  position: relative;
  top: 271px;
}

.itemrandom-desktop-line-center {
  height: 378px;
  left: 799px;
  position: absolute;
  top: -10px;
  width: 7px;
}

.reel {
  position: relative;
  width: 1500px;
  height: 200px;
  overflow: hidden;
  border: 1px solid black;
}

.items {
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: reel-anim 10s linear infinite;
          animation: reel-anim 10s linear infinite;
}

.item {
}

@-webkit-keyframes reel-anim {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(-900px);
            transform: translateX(-900px);
  }
}

@keyframes reel-anim {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(-900px);
            transform: translateX(-900px);
  }
}

.box-title-box-title {
  align-items: flex-start;
  display: flex;
  gap: 21px;
  justify-content: center;
  left: 581px;
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.box-title-info-box {
  align-items: flex-start;
  display: flex;
  gap: 10.29px;
  justify-content: center;
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.box-title-box-img {
  height: 85.7px;
  min-width: 78.18px;
  object-fit: cover;
  position: relative;
}

.box-title-div {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 15px;
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.box-title-namebox {
  color: #ffffff;
  font-family: "Prompt", Helvetica;
  font-size: 45.9px;
  font-weight: 700;
  height: 50.18px;
  letter-spacing: 0.23px;
  line-height: normal;
  margin-top: -1px;
  position: relative;
}

.box-title-descriptionbox {
  color: #ffffff;
  font-family: "Prompt", Helvetica;
  font-size: 14.2px;
  font-weight: 400;
  letter-spacing: 0.07px;
  line-height: normal;
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
