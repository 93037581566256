.shipping-process-desktop-product-list-wrapper {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 11px;
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.shipping-process-desktop-product-list {
  /* -webkit-backdrop-filter: blur(105px) brightness(100%); */
  align-items: flex-start;
  /* backdrop-filter: blur(105px) brightness(100%); */
  /* background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.01) 0%,
    rgba(0, 0, 0, 0.01) 100%
  ); */
  /* border: 1.05px solid; */
  border-radius: 10.5px;
  display: flex;
  flex-direction: column;
  gap: 10.5px;
  padding: 17.85px 13.65px;
  position: relative;
  max-width: 300px;
}

.shipping-process-desktop-product-list-detail {
  align-items: center;
  display: flex;
  gap: 36.75px;
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.shipping-process-desktop-product-item-in-the-box-preview {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 127.05px;
  justify-content: center;
  position: relative;
  width: 94.45px;
}

.shipping-process-desktop-product-info {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 1 1;
  flex-direction: column;
  gap: 19.09px;
  justify-content: center;
  position: relative;
}

.shipping-process-desktop-product-image-full {
  align-self: stretch;
  height: 48.5px;
  min-width: 94.45px;
  object-fit: contain;
  position: relative;
}

.shipping-process-desktop-description {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 12px;
  height: 30.51px;
  position: relative;
  width: 84.47px;
}

.shipping-process-desktop-product-name {
  align-self: stretch;
  color: #ffffff;
  font-family: "Prompt", Helvetica;
  font-size: 9px;
  font-weight: 500;
  letter-spacing: 0.04px;
  line-height: normal;
  margin-top: -0.65px;
  position: relative;
  overflow: hidden;
}

.shipping-process-desktop-product-brand {
  align-self: stretch;
  color: #ecc834;
  font-family: "Prompt", Helvetica;
  font-size: 9px;
  font-weight: 500;
  height: 7.08px;
  letter-spacing: 0.04px;
  line-height: normal;
  position: relative;
  text-align: center;
}

.shipping-process-desktop-BG-line-wrapper {
  background: linear-gradient(
    180deg,
    rgba(255, 204.39, 0, 0) 0%,
    rgba(255, 209.87, 27.63, 0.11) 100%
  );
  border-radius: 3.24px;
  height: 124px;
  left: calc(50% - 48px);
  position: absolute;
  top: calc(50% - 61px);
  width: 96px;
}

.shipping-process-desktop-BG-line {
  background-color: #ecc834;
  border-radius: 3.24px;
  height: 4px;
  position: relative;
  top: 120px;
}

.shipping-process-desktop-product-detail {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 24.15px;
  height: 100%;
  justify-content: space-between;
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding-top: 10px;
}


.shipping-process-desktop-p {
  color: #ffffff;
  font-family: "Montserrat", Helvetica;
  font-size: 16.8px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.05px;
  position: relative;
  width: 210px;
}

.shipping-process-desktop-product-price {
  align-items: center;
  display: flex;
  gap: 22.05px;
  justify-content: center;
  position: relative;
  width: 206.85px;
}



.shipping-process-desktop-text-wrapper-4 {
  color: #ffffff;
  font-family: "Montserrat", Helvetica;
  font-size: 16.8px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-left: -4.27px;
  margin-top: -1.05px;
  position: relative;
  width: 34.65px;
}

.shipping-process-desktop-text-wrapper-5 {
  color: #ecc834;
  font-family: "Montserrat", Helvetica;
  font-size: 16.8px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.05px;
  position: relative;
  width: 80px;
}

.shipping-process-desktop-text-wrapper-6 {
  color: #ecc834;
  font-family: "Montserrat", Helvetica;
  font-size: 16.8px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.05px;
  position: relative;
  width: 27.3px;
}

.shipping-process-desktop-text-wrapper-7 {
  color: #ffffff;
  font-family: "Montserrat", Helvetica;
  font-size: 12.6px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-right: -4.27px;
  
  margin-left: 10px;
  position: relative;
  width: 27.3px;
}

@media screen and (max-width: 450px) {
  .shipping-process-desktop-product-list-detail{
    scale:0.9;
  }
}