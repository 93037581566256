.itembox-desktop-itembox-desktop {
    align-items: center;
    background-color: #131313;
    border: 1px none;
    display: flex;
    flex-direction: column;
    /* width: 100vw;   */
    overflow: hidden;
    position: relative;
    background: linear-gradient(
        153deg,
        rgba(22, 20, 16, 1) 0%,
        rgba(15, 11, 4, 1) 16%,
        rgb(6, 5, 2) 45%,
        rgb(8, 6, 2) 74%,
        rgba(21, 19, 15, 1) 100%
    );
}
.inventory-item-name-title {
    text-shadow: 0px 0px 33.33333206176758px rgba(255, 216, 79, 0.28);
    font-family: Prompt;
    font-size: 33.333px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.167px;
    text-transform: uppercase;
    background: linear-gradient(90deg, #f0aa22 5.03%, #f5ce3d 28.13%, #fbe350 53.68%, #f3c636 77.27%, #f0aa22 99.39%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.itembox-desktop-BG {
    height: calc(100% - 666px);
    left: 1px;
    position: absolute;
    top: 666px;
    width: 100%;
}

.itembox-desktop-header-section {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    justify-content: center;
    padding: 61px 0px 0px;
    position: relative;
}

.itembox-desktop-header-BG {
    height: 900px;
    left: -6px;
    object-fit: cover;
    position: absolute;
    top: -4px;
    width: calc(100% + 10px);
    -webkit-mask-image: linear-gradient(to top, transparent 18%, rgb(0, 0, 0) 30%);
            mask-image: linear-gradient(to top, transparent 18%, rgb(0, 0, 0) 30%);
}

.itembox-desktop-div {
    align-items: start;
    width: 100%;
    display: flex;
}

@-webkit-keyframes wave-animation {
    0% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
    50% {
        -webkit-transform: translateY(-4px);
                transform: translateY(-4px);
    }
    100% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
}

@keyframes wave-animation {
    0% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
    50% {
        -webkit-transform: translateY(-4px);
                transform: translateY(-4px);
    }
    100% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
}

.itembox-desktop-live-icon {
    background-color: #ffb500;
    border-radius: 3.5px;
    height: 7px;
    min-width: 7px;
    position: relative;
}

.itembox-desktop-text-wrapper {
    background: linear-gradient(90deg, rgba(240, 170, 34, 1) 0%, rgba(249, 219, 72, 1) 49%, rgba(240, 170, 34, 1) 100%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;

    font-family: "Prompt", Helvetica;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0.1px;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.itembox-desktop-rectangle {
    -webkit-backdrop-filter: blur(79.48px) brightness(100%);
    backdrop-filter: blur(79.48px) brightness(100%);
    background-color: #ff6d04;
    border-radius: 7.15px 0px 0px 7.15px;
    height: 62px;
    left: -1px;
    position: relative;
    top: -1px;
    width: 7px;
}

.itembox-desktop-name-item-label {
    text-overflow: ellipsis;
    align-self: stretch;
    color: linear-gradient(90deg, rgba(240, 170, 34, 1) 0%, rgba(249, 219, 72, 1) 49%, rgba(240, 170, 34, 1) 100%);
    font-family: "Prompt", Helvetica;
    font-size: 15.2px;
    font-weight: 700;
    height: 11.05px;
    letter-spacing: 0.08px;
    line-height: 13.3px;
    position: relative;
    white-space: nowrap;
}

.itembox-desktop-frame {
    align-items: center;
    align-self: stretch;
    display: flex;
    gap: 7px;
    height: 24.64px;
    position: relative;
    padding-right: 8px;
}

.itembox-desktop-icon-box {
    height: 24.64px;
    min-width: 25.43px;
    object-fit: cover;
    position: relative;
}

.itembox-desktop-box-label {
    color: #ffffff;
    flex: 1 1;
    font-family: "Prompt", Helvetica;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.07px;
    line-height: 12.2px;
    position: relative;
}

.itembox-desktop-box-content {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 52px;
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.itembox-desktop-header-name-box {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 13px;
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.itembox-desktop-box-name-group {
    align-items: flex-end;
    display: flex;
    gap: 20px;
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.itembox-desktop-name-box-label {
    color: #ffffff;
    font-family: "Prompt", Helvetica;
    font-size: 30px;
    font-weight: 700;
    height: 33px;
    letter-spacing: 0.15px;
    line-height: normal;
    margin-top: -0.94px;
    position: relative;
    width: 232px;
}

.itembox-desktop-price-box-label {
    color: #f0b922;
    font-family: "Prompt", Helvetica;
    font-size: 28.1px;
    font-weight: 700;
    height: 33px;
    letter-spacing: 0.14px;
    line-height: normal;
    margin-top: -0.94px;
    position: relative;
    width: 129px;
}

.itembox-desktop-description-label {
    color: #ffffff;
    font-family: "Prompt", Helvetica;
    font-size: 15.4px;
    font-weight: 400;
    letter-spacing: 0.08px;
    line-height: normal;
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.itembox-desktop-box-image {
    height: 320px;
    min-width: 322px;
    position: relative;
}

.itembox-desktop-random-button {
    align-items: flex-start;
    display: flex;
    gap: 52px;
    justify-content: center;
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.itembox-desktop-img {
    height: 92px;
    left: calc(50% - 157px);
    object-fit: cover;
    position: absolute;
    top: calc(50% - 46px);
    width: 314px;
}

.itembox-desktop-open-box-label {
    color: #1c1100;
    font-family: "Prompt", Helvetica;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0.1px;
    line-height: 17.5px;
    margin-top: -1px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.itembox-desktop-iteminthebox-title {
    align-items: center;
    display: flex;
    gap: 69px;
    height: 151px;
    justify-content: center;
    position: relative;
    /* width: 100vw; */
}

.itembox-desktop-graphic {
    height: 7.77px;
    min-width: 370.59px;
    position: relative;
}

.itembox-desktop-div-2 {
    height: 8px;
    position: relative;
    width: 371px;
}

.itembox-desktop-line-graph {
    height: 2px;
    left: 0;
    position: absolute;
    top: 3px;
    width: 365px;
}

.itembox-desktop-circle-graph {
    background-color: #f8d745;
    border-radius: 3.38px/3.89px;
    height: 8px;
    left: 364px;
    position: absolute;
    top: 0;
    width: 7px;
}

.itembox-desktop-header-label-wrapper {
    height: 56px;
    width: 534px;
    position: relative;
}
@media (max-width: 500px) {
    .itembox-desktop-header-label-wrapper {
        width: 400px;
        align-items: center;
        align-content: center;
    }
}
.itembox-desktop-header-label {
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent;
    background: linear-gradient(180deg, rgb(248, 215, 69) 0%, rgb(233.75, 161.4, 7.79) 100%);
    background-clip: text;
    color: transparent;
    font-family: "Prompt", Helvetica;
    font-size: 64px;
    font-weight: 700;
    left: 0;
    letter-spacing: 0.32px;
    line-height: 56.1px;
    position: absolute;
    text-align: center;
    text-fill-color: transparent;
    text-shadow: 0px 11.65px 11.65px #00000040;
    top: 0;
    white-space: nowrap;
}

@media (max-width: 500px) {
    .itembox-desktop-header-label {
        font-size: 32px;
        text-align: center;
        position: relative;
    }
}

.itembox-desktop-overlap-wrapper {
    height: 7.77px;
    width: 370.59px;
    position: relative;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

@media (max-width: 500px) {
    .itembox-desktop-overlap-wrapper {
        width: 0;
        display: none;
    }
    .itembox-desktop-graphic {
        width: 0;
        display: none;
    }
}
.itembox-desktop-line-graph-2 {
    height: 2px;
    left: 0;
    position: absolute;
    top: 3px;
    -webkit-transform: rotate(-180deg);
            transform: rotate(-180deg);
    width: 365px;
}

.itembox-desktop-item-list-section {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 28px;
    justify-content: center;
    position: relative;
    width: 1438px;
}

.itembox-desktop-item-row {
    /* align-items: center;
  display: flex;
  gap: 31px;
  justify-content: center;
  margin-top: -4.5px;
  position: relative; */
    width: 100%;
}

.itembox-desktop-box {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 26px;
    position: relative;
    width: 383px;
}

.itembox-desktop-box-image-2 {
    height: 250px;
    min-width: 500px;
    position: relative;
    -webkit-animation: shake2 4s ease-in-out infinite;
            animation: shake2 4s ease-in-out infinite;
}

@-webkit-keyframes shake2 {
    0% {
        -webkit-transform: translate(0);
                transform: translate(0);
    }
    10% {
        -webkit-transform: translate(-1px, -1px);
                transform: translate(-1px, -1px);
    }
    20% {
        -webkit-transform: translate(1px, 1px);
                transform: translate(1px, 1px);
    }
    30% {
        -webkit-transform: translate(-1px, -1px);
                transform: translate(-1px, -1px);
    }
    40% {
        -webkit-transform: translate(1px, 0);
                transform: translate(1px, 0);
    }
    50% {
        -webkit-transform: translate(-1px, -1px);
                transform: translate(-1px, -1px);
    }
    60% {
        -webkit-transform: translate(1px, 0);
                transform: translate(1px, 0);
    }
    70% {
        -webkit-transform: translate(-1px, -1px);
                transform: translate(-1px, -1px);
    }
    80% {
        -webkit-transform: translate(1px, 0);
                transform: translate(1px, 0);
    }
    90% {
        -webkit-transform: translate(-1px, -1px);
                transform: translate(-1px, -1px);
    }
    100% {
        -webkit-transform: translate(0);
                transform: translate(0);
    }
}

@keyframes shake2 {
    0% {
        -webkit-transform: translate(0);
                transform: translate(0);
    }
    10% {
        -webkit-transform: translate(-1px, -1px);
                transform: translate(-1px, -1px);
    }
    20% {
        -webkit-transform: translate(1px, 1px);
                transform: translate(1px, 1px);
    }
    30% {
        -webkit-transform: translate(-1px, -1px);
                transform: translate(-1px, -1px);
    }
    40% {
        -webkit-transform: translate(1px, 0);
                transform: translate(1px, 0);
    }
    50% {
        -webkit-transform: translate(-1px, -1px);
                transform: translate(-1px, -1px);
    }
    60% {
        -webkit-transform: translate(1px, 0);
                transform: translate(1px, 0);
    }
    70% {
        -webkit-transform: translate(-1px, -1px);
                transform: translate(-1px, -1px);
    }
    80% {
        -webkit-transform: translate(1px, 0);
                transform: translate(1px, 0);
    }
    90% {
        -webkit-transform: translate(-1px, -1px);
                transform: translate(-1px, -1px);
    }
    100% {
        -webkit-transform: translate(0);
                transform: translate(0);
    }
}

.itembox-desktop-text-wrapper-2 {
    align-self: stretch;
    color: #ffffff;
    font-family: "Prompt", Helvetica;
    font-size: 32px;
    font-weight: 700;
    letter-spacing: 0.16px;
    line-height: normal;
    position: relative;
    text-align: center;
}

.itembox-desktop-item-row-2 {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 28px;
    position: relative;
    width: 864px;
}

.item-desktop-item-flex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 30px 10px;
    border: 3px solid #716544;
    border-radius: 29px;
    background: rgb(77, 69, 50);
    background: linear-gradient(167deg, rgba(77, 69, 50, 1) 0%, rgba(36, 31, 22, 1) 35%, rgba(23, 20, 16, 1) 100%);
}

.itembox-desktop-row {
    align-items: flex-end;
    display: flex;
    gap: 23.77px;
    height: 253px;
    position: relative;
    width: 837px;
}

.itembox-desktop-product-item-in-the-box-preview {
    overflow-y: hidden;
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 254px;
    justify-content: center;
    margin-top: -1px;
    position: relative;
    width: 198px;
}

.itembox-desktop-product-info {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 1 1;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    position: relative;
}

.itembox-desktop-price {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.itembox-desktop-price-label {
    color: #ffffff;
    font-family: "Prompt", Helvetica;
    font-size: 18px;
    font-weight: 500;
    height: 30px;
    letter-spacing: 0.09px;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    text-align: center;
    width: 148px;
}

.itembox-desktop-price-BG {
    height: 32px;
    left: calc(50% - 74px);
    object-fit: cover;
    position: absolute;
    top: calc(50% - 16px);
    width: 148px;
}
.itembox-desktop-product-image {
    height: 165px;
    width: 200px;
    object-fit: contain;
    align-self: stretch;
    position: relative;
    margin: 0 auto;

    /*    object-fit: cover;
    position: absolute;
    top: 0;
    width: 204px; */
}

.itembox-desktop-item-product {
    -webkit-filter: drop-shadow(0px 0px 10px #e5bb336b);
            filter: drop-shadow(0px 0px 10px #e5bb336b);
    margin: 1px;
}

.itembox-desktop-overlap-group-2 {
    height: 326px;
    position: relative;
    width: 230px;
}

.itembox-desktop-description {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 78px;
    position: relative;
    width: 155px;
}

.itembox-desktop-product-name {
    color: #ffffff;
    font-family: "Prompt", Helvetica;
    font-size: 14px;
    font-weight: 600;
    height: 26px;
    left: 12px;
    letter-spacing: 0.1px;
    line-height: 17.5px;
    position: absolute;
    text-align: center;
    top: 178px;
    width: 208px;
}

.itembox-desktop-product-brand {
    align-self: stretch;
    font-family: "Prompt", Helvetica;
    font-size: 16.4px;
    font-weight: 500;
    height: 13px;
    letter-spacing: 0.08px;
    line-height: normal;
    position: relative;
    text-align: center;
}

.itembox-desktop-BG-line-wrapper {
    background: linear-gradient(180deg, rgba(255, 204.39, 0, 0) 0%, rgba(255, 209.87, 27.63, 0.11) 100%);
    border-radius: 5.94px;
    height: 227px;
    left: calc(50% - 88px);
    position: absolute;
    top: calc(50% - 114px);
    width: 176px;
}

.itembox-desktop-BG-line {
    border-radius: 5.94px;
    height: 7px;
    position: relative;
    top: 220px;
}

.itembox-desktop-article {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 254px;
    justify-content: center;
    margin-right: -26.31px;
    margin-top: -1px;
    position: relative;
    width: 198px;
}

.itembox-desktop-item-row-3 {
    width: -moz-fit-content;
    width: -webkit-fit-content;
    width: fit-content;
    align-items: flex-start;
    gap: "32.769999999999996px";
    display: flex;
    position: relative;
    flex-wrap: wrap;
    padding-left: 50px;
}

.itembox-desktop-product-item-in-the-box-preview-2 {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 254px;
    justify-content: center;
    position: relative;
    width: 198px;
}

.itembox-desktop-item-row-4 {
    align-items: flex-end;
    display: flex;
    gap: 23.77px;
    margin-bottom: -4.5px;
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

/*  mobile */
.itembox-desktop-item-list-section-mobile {
    width: 100vw;
}
.itembox-desktop-box-image-2-mobile {
    z-index: 1;
    margin-top: 30px;
    position: relative;
}

.item-name-title {
    background: linear-gradient(90.47deg, #f0aa22 5.03%, #f5ce3d 28.13%, #fbe350 53.68%, #f3c636 77.27%, #f0aa22 99.39%),
        linear-gradient(180deg, #fffa78 0%, rgba(251, 255, 70, 0.19) 100%);
    -webkit-background-clip: text; /* For Safari/WebKit browsers */
    background-clip: text;
    color: transparent;
}

.search-dialog-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    z-index: 1;
}
