.login-desktop {
  align-items: center;
  /* background-color: #b3b0b0; */
  border: 1px none;
  display: flex;
  flex-direction: column;
  min-width: 100vw;
  /* overflow-x: hidden; */
  position: relative;
}

.login-desktop-bg {
  background: rgb(40, 219, 194);
  background: linear-gradient(135deg,
  rgba(255, 170, 11, 0.233) 0%,
  rgb(47, 41, 33) 20%,
  rgb(29, 26, 23) 50%,
  rgb(20, 20, 19) 100%);
 
  object-fit: contain;
  height: 100vh;
  position: fixed;
  width: 100vw;
  left: 0px;
  z-index: 0;
}

.auth-container {
  color: white;
  /* box-shadow: 0 0 61px #0ca7ff; */
}

.primary-bg {
  background: rgb(40, 219, 194);
  background: linear-gradient(90deg,
      rgba(40, 219, 194, 1) 0%,
      rgba(67, 131, 222, 1) 52%,
      rgba(136, 80, 243, 1) 100%);
}

.primary-border {
  border-image: linear-gradient(90deg,
      rgba(40, 219, 194, 1) 0%,
      rgba(67, 131, 222, 1) 52%,
      rgba(136, 80, 243, 1) 100%) 1;
}

@media screen and (max-width:768px) {
  .auth-container {
    width: 100vw
  }

  .autBox {
    width: 100%
  }
}

.flex-container {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  justify-content: center;
}


.logging-in-loader {
  width: 24px;
  height: 24px;
  border: 5px solid #FFF;
  margin-right: 10px;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  -webkit-animation: rotation 1s linear infinite;
          animation: rotation 1s linear infinite;
}

@-webkit-keyframes rotation {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes rotation {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}